<template>
  <div class="block">
    <h2>{{ title }}</h2>
    <SearchBox @create="createContent" @search="searchContents" :showCreateButton="showCreateButton"/>
    <FilterCheckboxes
        :onlyPriority2="onlyPriority2"
        :onlyPriority4="onlyPriority4"
        :path="path"
        :filters="filters"
        :tags="tags"
        @onFilterChange="filterContents"/>
    <span style="border-bottom: 2px solid #9b9b9b; margin-top: 10px; margin-bottom: 10px; display: block;"></span>
    <PlaylistEditToolContainer
        v-if="itemContainer === 'PlaylistContentItemContainer' || itemContainer === 'SmallContentItemContainer'"
        idPrefix="content1"
        :allIsSelected="isAllSelected"
        :is-selected="isAllSelected"
        :selected-count="selectedCount"
        button-label="추가하기"
        @select-all="selectAllItems"
        @button-clicked="addButtonClicked"
    />
    <draggable class="object-list"
               v-if="itemContainer === 'PlaylistContentItemContainer' || itemContainer === 'SmallContentItemContainer'"
               :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
               v-model="paginatedContents"
               v-bind="dragOptions"

               @start="drag = true"
               @end="drag = false"
               item-key="_id"
               :group="{ name: group, pull: 'clone'}"

    >
      <template #item="{ element, index }">
        <component :is="itemContainer"
                   :content="element"
                   :index="calculateGlobalIndex(index)"
                   :isSelected="selecteds[index]"
                   @copy="copyContent(element._id)"
                   @edit="editContent(element._id)"
                   @delete="deletingContent = element; this.showDeletePopup = true"
                   @favorite="updateFavorite"
                   @select="updateSelected"
        />
      </template>
    </draggable>
    <div class="object-list" v-else>
      <component :is="itemContainer"
                 v-for="(content, index) in paginatedContents"
                 :key="content._id"
                 :content="content"
                 :index="calculateGlobalIndex(index)"
                 @copy="copyContent(content._id)"
                 @edit="editContent(content._id)"
                 @delete="deletingContent = content; this.showDeletePopup = true"
                 @favorite="updateFavorite"
      />
    </div>
    <Pagination :total-items="totalContents" :items-per-page="itemsPerPage" :current-page="currentPage"
                :use-page-param="usePageParam"
                @page-changed="handlePageChange"/>
  </div>
  <ConfirmPopup :message="deletingContent.message.substring(0, Math.min(10, deletingContent.message.length)) + ' CONTENT를 삭제하시겠습니까?'"
                @confirm="deleteContent(deletingContent._id); showDeletePopup = false;" @close="showDeletePopup = false" v-if="showDeletePopup"/>
</template>

<script>
import draggable from 'vuedraggable'
import ContentItemContainer from './ContentItemContainer.vue';
import SmallContentItemContainer from './SmallContentItemContainer.vue';
import PlaylistContentItemContainer from "./PlaylistContentItemContainer.vue";
import SearchBox from './SearchBox.vue';
import FilterCheckboxes from './FilterCheckboxes.vue';
import Pagination from './ListPagination.vue';
import axios from 'axios';
import ConfirmPopup from "@/components/cms/ConfirmPopup.vue";
import PlaylistPopup from "@/components/cms/PlaylistPopup.vue";
import PlaylistEditToolContainer from "@/components/cms/PlaylistEditToolContainer.vue";

export default {
  components: {
    PlaylistEditToolContainer,
    PlaylistPopup, ConfirmPopup,
    draggable,
    ContentItemContainer,
    SmallContentItemContainer,
    PlaylistContentItemContainer,
    SearchBox,
    FilterCheckboxes,
    Pagination,
  },
  props: {
    itemContainer: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    group: {
      type: String,
      default: ''
    },
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    onlyPriority2: {
      type: Boolean,
      default: false,
    },
    onlyPriority4: {
      type: Boolean,
      default: false,
    },
    usePageParam: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      drag: false,
      contents: [],
      filteredContents: [],
      paginatedContents: [],
      selecteds: [],
      currentPage: 1,
      totalContents: 0,
      totalPages: 0,
      searchText: '',
      filters: {
        favorite: false,
        priority_2: this.onlyPriority2,
        priority_4: this.onlyPriority4,
      },
      tags: [
        {name: '1', value: false},
        {name: '2', value: false},
        {name: '3', value: false},
        {name: '4', value: false},
        {name: '5', value: false},
        {name: '6', value: false},
        {name: '7', value: false},
        {name: '8', value: false},
        {name: '9', value: false},
        {name: '10', value: false},
        {name: '11', value: false},
        {name: '12', value: false},
      ],
      deletingContent: null,
      showDeletePopup: false,
      isAllSelected: false,
    };
  },
  created() {
    this.currentPage = this.initialPage;
    this.loadContents()
  },
  watch: {
    '$route': 'loadContents'
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    selectedCount() {
      return this.selecteds.filter(selected => selected).length;
    },
  },
  methods: {
    selectAllItems(isSelected) {
      this.selecteds = this.paginatedContents.map(() => isSelected);
      this.isAllSelected = isSelected;
    },
    updateSelected({ selected, id}) {
      const relativeIndex = this.paginatedContents.findIndex(content => content._id === id);
      if (relativeIndex !== -1) {
        this.selecteds[relativeIndex] = selected;
      }
      this.isAllSelected = this.selecteds.every(selected => selected);
    },
    addButtonClicked() {
      const selectedContents = this.contents.filter((content, index) => this.selecteds[index - ((this.currentPage - 1) * this.itemsPerPage)]);
      let tempArray = []
      for (let content of selectedContents) {
        for(let i = 0; i < content.repeat; i++) {
          tempArray.push(structuredClone(content))
        }
      }
      this.$emit('add-selected', tempArray);
    },
    sort() {
      this.contents = this.contents.sort((a, b) => a.order - b.order);
    },
    handlePageChange(page) {
      if (page === -1) {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      } else if (page === -2) {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      } else if (page === -3) {
        this.currentPage = this.totalPages;
      } else {
        this.currentPage = page;
      }
      this.setPaginatedContents();
      this.isAllSelected = false;
    },
    createContent() {
      this.$router.push(`/${this.path}/new`);
    },
    loadContents() {
      axios.get(`/cms/${this.path}/list`)
          .then(response => {
            this.contents = response.data;
            const urlParams = new URLSearchParams(window.location.search);
            const filters = urlParams.get('filters');
            const tags = urlParams.get('tags');
            const searchText = urlParams.get('searchText');
            if (filters) {
              this.filters = {
                favorite: filters[0] === '1',
                priority_2: filters[1] === '1',
                priority_4: filters[2] === '1',
              };
            }
            if (tags) {
              this.tags = tags.split('').map(tag => ({value: tag === '1'}));
            }
            if (searchText) {
              this.searchText = searchText;
            }
            this.applyFiltersAndSearch(this.searchText, this.filters, this.tags);
            const p = urlParams.get('p');
            if (p !== null && !isNaN(p) && p > 0) {
              const page = parseInt(p);
              if (!isNaN(page) && page <= this.totalPages) {
                this.currentPage = page;
                this.setPaginatedContents();
              }
            }

            this.contents = this.filteredContents;  //최초 로드 후 필터된 콘텐츠로 저장

            // Reset all selected state
            this.isAllSelected = false;
          })
          .catch(error => {
            console.error(error);
          });
    },
    filterContents(filters, tags) {
      //if any of filters is true, add parameters to url
      this.currentPage = 1;
      let query = {};
      if (filters.favorite || filters.priority_2 || filters.priority_4) {
        query.filters = [filters.favorite, filters.priority_2, filters.priority_4].map(filter => filter ? 1 : 0).join('');
      }
      if (tags.some(tag => tag.value)) {
        query.tags = tags.map(tag => tag.value ? 1 : 0).join('');
      }
      if (this.searchText) {
        query.searchText = this.searchText;
      }
      this.$router.push({path: this.$route.path, query: query});
      this.applyFiltersAndSearch(this.searchText, filters, tags);
    },
    searchContents(searchText) {
      this.currentPage = 1;
      let query = {};
      if (this.filters.favorite || this.filters.priority_2 || this.filters.priority_4) {
        query.filters = [this.filters.favorite, this.filters.priority_2, this.filters.priority_4].map(filter => filter ? 1 : 0).join('');
      }
      if (this.tags.some(tag => tag.value)) {
        query.tags = this.tags.map(tag => tag.value ? 1 : 0).join('');
      }
      if (searchText  !== '') {
        query.searchText = searchText;
      }
      this.$router.push({path: this.$route.path, query: query});
      this.applyFiltersAndSearch(searchText, this.filters, this.tags);
    },
    applyFiltersAndSearch(searchText, filters, tags) {
      this.searchText = searchText;
      this.filters = filters;
      this.tags = tags;
      let filtered = this.contents.filter(content => {
        if (filters.favorite && !content.favorite) {
          return false;
        }
        if ((filters.priority_2 && (content.priority !== 2 && content.priority !== 5)) || (this.onlyPriority2 && (content.priority !== 2 && content.priority !== 5))) {
          return false;
        }
        if ((filters.priority_4 && (content.priority === 2 || content.priority === 5)) || (this.onlyPriority4 && (content.priority === 2 || content.priority === 5))) {
          return false;
        }
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].value && !content.tags.includes(i + 1)) {
            return false;
          }
        }
        return true;
      });

      if (searchText !== '') {
        filtered = filtered.filter(content => {
          return content.message.toLowerCase().includes(searchText.toLowerCase()) ||
              content.message.toLowerCase().replace(/\s/g, '').includes(searchText.toLowerCase().replace(/\s/g, ''));
        });
      }

      this.filteredContents = filtered;
      this.totalContents = this.filteredContents.length;
      this.totalPages = Math.ceil(this.totalContents / this.itemsPerPage);
      this.setPaginatedContents();
    },
    setPaginatedContents() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedContents = this.filteredContents.slice(start, end);
      this.selecteds = this.paginatedContents.map(() => false);
    },
    copyContent(contentId) {
      try {
        axios.post('/cms/content/duplicate', {contentId: contentId})
            .then(() => {
              this.loadContents();
            })
            .catch(error => {
              console.error(error);
            });
      } catch (error) {
        console.error(error);
      }
    },
    editContent(contentId) {
      this.$router.push({
        name: this.path === 'content' ? 'ContentEditPage' : 'EPContentEditPage',
        params: {contentId: contentId},
      });
    },
    deleteContent(contentId) {
      try {
        axios.post('/cms/content/delete', {contentId: contentId})
            .then(() => {
              this.loadContents();
            })
            .catch(error => {
              console.error(error);
            });
      } catch (error) {
        console.error(error);
      }
    },
    async updateFavorite({favorite, id}) {
      try {
        await axios.post('/cms/content/save', {favorite, id});
        const content = this.contents.find(content => content._id === id);
        if (content) {
          content.favorite = favorite;
        }
      } catch (error) {
        console.error(error);
      }
    },
    calculateGlobalIndex(index) {
      return ((this.currentPage - 1) * this.itemsPerPage) + index;
    },
  },
};
</script>

<style scoped>

.block {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
}

.object-list {
  display: grid;
  row-gap: 9px;
}

.ghost {
  opacity: 0.5;
}

.list-move {
  transition: transform 0.5s;
}
</style>
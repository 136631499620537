import {createRouter, createWebHistory} from 'vue-router'
import TimelinePage from '@/components/cms/TimelinePage.vue'
import PlaylistPage from "@/components/cms/PlaylistPage.vue";
import ContentPage from "@/components/cms/ContentPage.vue";
import ContentEditPage from "@/components/cms/ContentEditPage.vue";
import EPContentPage from "@/components/cms/EPContentPage.vue";
import PlaylistEditPage from "@/components/cms/PlaylistEditPage.vue";
import LogPage from "@/components/cms/LogPage.vue";
import TagPage from "@/components/cms/TagPage.vue";
import LoginPage from "@/components/cms/LoginPage.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/timeline',
            name: 'TimelinePage',
            component: TimelinePage,
            props: true
        },
        {
            path: '/playlist',
            name: 'PlaylistPage',
            component: PlaylistPage
        },
        {
            path: '/playlist/edit/:playlistId',
            name: 'PlaylistEditPage',
            component: PlaylistEditPage
        },
        {
            path: '/content/list',
            name: 'ContentPage',
            component: ContentPage
        },
        {
            path: '/content/edit/:contentId',
            name: 'ContentEditPage',
            component: ContentEditPage,
        },
        {
            path: '/content/new',
            name: 'NewContentPage',
            component: ContentEditPage
        },
        {
            path: '/epcontent/list',
            name: 'EPContentPage',
            component: EPContentPage
        },
        {
            path: '/epcontent/edit/:contentId',
            name: 'EPContentEditPage',
            component: ContentEditPage
        },
        {
            path: '/epcontent/new',
            name: 'NewEpContentPage',
            component: ContentEditPage
        },
        {
            path: '/log',
            name: 'LogPage',
            component: LogPage
        },
        {
            path: '/tag',
            name: 'TagPage',
            component: TagPage
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: LoginPage
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/timeline'
        }
    ]
})

router.beforeEach((to, from, next) => {
  // Create a new object with only the properties you need
  const previousRoute = {
    path: from.path,
    params: from.params,
    query: from.query,
  };

  // Save the new object to the local storage
  localStorage.setItem('previousRoute', JSON.stringify(previousRoute));

  next();
});

export default router
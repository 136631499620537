<template>
  <div class="block">
    <h2>PLAYLIST</h2>
    <SearchBox @create="create" @search="searchPlaylists"/>
    <PlaylistFilterCheckboxes :filters="filters"
        @onFilterChange="filterPlaylists"/>
    <span style="border-bottom: 2px solid #9b9b9b; margin-top: 10px; margin-bottom: 10px; display: block;"></span>
    <!-- 플레이리스트 목록 -->

    <draggable class="object-list" v-if="draggable"
               :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
               v-model="paginatedPlaylists"
               v-bind="dragOptions"
               @start="drag = true"
               @end="drag = false"
               item-key="_id"
               :group="{ name: group, pull: 'clone' }"
    >
      <template #item="{ element }">
        <PlaylistItemContainer
            :small="small"
            :playlist="element"
            @copy="copyPlaylist(element._id)"
            @edit="editPlaylist(element._id)"
            @delete="deletingPlaylist = element; this.showDeletePopup = true"
            @favorite="updateFavorite"
        />
      </template>
    </draggable>
    <div class="object-list" v-else>

      <PlaylistItemContainer
          v-for="(playlist, index) in paginatedPlaylists"
          :key="index"
          :small="small"
          :playlist="playlist"
          :index="calculateGlobalIndex(index)"
          @copy="copyPlaylist(playlist._id)"
          @edit="editPlaylist(playlist._id)"
          @delete="deletingPlaylist = playlist; this.showDeletePopup = true"
          @favorite="updateFavorite"
      />
    </div>
    <Pagination :total-items="totalPlaylists" :items-per-page="itemsPerPage" :current-page="currentPage" :use-page-param="usePageParam"
                @page-changed="handlePageChange"/>
  </div>
  <PlaylistPopup v-if="showPlaylistPopup" :playlist="editingPlaylist" @close="closePopup"/>
  <ConfirmPopup :message="deletingPlaylist.title + ' PLAYLIST를 삭제하시겠습니까?'"
                @confirm="deletePlaylist(deletingPlaylist._id); showDeletePopup = false;" @close="showDeletePopup = false" v-if="showDeletePopup"/>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios';
import PlaylistItemContainer from './PlaylistItemContainer.vue';
import SearchBox from './SearchBox.vue';
import PlaylistFilterCheckboxes from './PlaylistFilterCheckboxes.vue';
import Pagination from './ListPagination.vue';
import PlaylistPopup from './PlaylistPopup.vue';
import ConfirmPopup from "@/components/cms/ConfirmPopup.vue";

export default {
  components: {
    ConfirmPopup,
    draggable,
    PlaylistItemContainer,
    SearchBox,
    PlaylistFilterCheckboxes,
    Pagination,
    PlaylistPopup,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: '',
    },
    usePageParam: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      drag: false,
      playlists: [],
      filteredPlaylists: [],
      editingPlaylist: null,
      deletingPlaylist: null,
      showDeletePopup: false,
      showPlaylistPopup: false,
      currentPage: 1,
      totalPlaylists: 0,
      totalPages: 0,
      searchText: '',
      filters: {
        favorite: false,
      },
    };
  },
  created() {
    this.loadPlaylists();
  },
  computed: {
    paginatedPlaylists() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPlaylists.slice(start, end);
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    handlePageChange(page) {
      if (page === -1) {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      } else if (page === -2) {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      } else if (page === -3) {
        this.currentPage = this.totalPages;
      } else {
        this.currentPage = page;
      }
    },
    loadPlaylists() {
      axios.get('/cms/playlist/list')
          .then((response) => {
            this.playlists = response.data;
            this.filteredPlaylists = this.playlists;
            this.totalPlaylists = this.playlists.length;
            this.totalPages = Math.ceil(this.totalPlaylists / this.itemsPerPage);
            const urlParams = new URLSearchParams(window.location.search);
            const filters = urlParams.get('pfilters');
            const searchText = urlParams.get('searchText');
            if (filters) {
              this.filters = {
                favorite: filters[0] === '1',
              };
            }
            if (searchText) {
              this.searchText = searchText;
            }
            this.applyFiltersAndSearch(this.searchText, this.filters, this.tags);
            const p = urlParams.get('p');
            if (p !== null && !isNaN(p) && p > 0) {
              const page = parseInt(p);
              if (!isNaN(page) && page <= this.totalPages) {
                this.currentPage = page;
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
    filterPlaylists(filters) {
      this.currentPage = 1;
      let query = {};
      if (filters.favorite){
        query.pfilters = '1';
      }
      if (this.searchText) {
        query.searchText = this.searchText;
      }
      this.$router.push({path: this.$route.path, query: query});
      this.applyFiltersAndSearch(this.searchText, filters);
    },
    searchPlaylists(searchText) {
      this.currentPage = 1;
      let query = {};
      if (this.filters.favorite) {
        query.pfilters = '1';
      }
      if (searchText  !== '') {
        query.searchText = searchText;
      }
      this.$router.push({path: this.$route.path, query: query});
      this.applyFiltersAndSearch(searchText, this.filters);
    },
    applyFiltersAndSearch(searchText, filters) {
      this.searchText = searchText;
      this.filters = filters;
      let filtered = this.playlists.filter(playlist => {
        return !(filters.favorite && !playlist.favorite);
      });

      if (searchText !== '') {
        filtered = filtered.filter(playlist => {
          return playlist.title.toLowerCase().includes(searchText.toLowerCase()) ||
              playlist.title.toLowerCase().replace(/\s/g, '').includes(searchText.toLowerCase().replace(/\s/g, ''));
        });
      }

      this.filteredPlaylists = filtered;
      this.totalPlaylists = filtered.length;
      this.totalPages = Math.ceil(this.totalPlaylists / this.itemsPerPage);
    },
    copyPlaylist(playlistId) {
      try {
        axios.post('/cms/playlist/duplicate', {playlistId: playlistId})
            .then(() => {
              this.loadPlaylists();
            })
            .catch((error) => {
              console.error(error);
            });
      } catch (error) {
        console.error(error);
      }
    },
    editPlaylist(playlistId) {
      try {
        this.editingPlaylist = this.playlists.find((playlist) => playlist._id === playlistId);
        this.showPlaylistPopup = true;
      } catch (error) {
        console.error(error);
      }
    },
    deletePlaylist(playlistId) {
      try {
        axios.post('/cms/playlist/delete', {playlistId: playlistId})
            .then(() => {
              this.loadPlaylists();
            })
            .catch((error) => {
              console.error(error);
            });
      } catch (error) {
        console.error(error);
      }
    },
    async updateFavorite({favorite, id}) {
      try {
        await axios.post('/cms/playlist/save', {favorite, id});
        const playlist = this.playlists.find((playlist) => playlist._id === id);
        if (playlist) {
          playlist.favorite = favorite;
        }
      } catch (error) {
        console.error(error);
      }
    },
    create() {
      this.editingPlaylist = null;
      this.showPlaylistPopup = true;
    },
    closePopup(reload = false) {
      this.showPlaylistPopup = false;
      if (reload) {
        this.loadPlaylists();
      }
    },
    calculateGlobalIndex(index) {
      return ((this.currentPage - 1) * this.itemsPerPage) + index;
    },
  },
};
</script>

<style scoped>
.block {
  background-color: #fff; /* White background color */
  border-radius: 10px; /* Set the corner radius to 10px for rounded corners */
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1); /* Add a slight drop shadow */
  padding: 10px 20px; /* Add some padding inside the block */
}

.object-list {
  display: grid;
  row-gap: 9px; /* Add a 9px gap between the rows */
}
</style>
<template>
  <div class="container">
    <ContentContainer
        itemContainer="ContentItemContainer"
        title="CONTENT"
        path="content"
        :initialPage="currentPage"
        :itemsPerPage="12"
    />
  </div>
</template>

<script>
import ContentContainer from './ContentContainer.vue';

export default {
  components: {
    ContentContainer,
  },

  data() {
    return {
      currentPage: 1,
    }
  },
  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1;
  }
};
</script>

<style scoped>
.container {
  display: grid;
  gap: 20px;
}
</style>
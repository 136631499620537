<template>
  <button type="button" class="icon-button" :disabled="!enabled" @click="callbacks.copy">
    복제하기
  </button>
  <button type="button" class="icon-button" :disabled="!enabled" @click="callbacks.edit">
    수정하기
  </button>
  <button type="button" class="icon-button" :disabled="!enabled" @click="callbacks.delete">
    삭제하기
  </button>
</template>

<script>
export default {
  name: 'ActionButtons',
  props: {
    enabled: {
      type: Boolean,
      required: true
    },
    callbacks: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
button {
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
  padding: 0 10px; /* Add padding to the sides of the button */
}

button:disabled {
  background-color: #c4c4c6; /* Set disabled button background color */
  color: #686868; /* Set disabled button text color */
  cursor: not-allowed; /* Disable cursor for disabled button */
}

.icon-button {
  width: auto; /* Adjust the width to fit the content */
}
</style>
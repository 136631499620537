<template>
  <div class="popup-overlay" @click.self="negative">
    <div class="popup-content">
      <h2>{{ this.message }}</h2>
      <div class="button-group">
        <button id="positive-button" @click="positive">예</button>
        <button id="negative-button" @click="negative">아니요</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPopup',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    negative() {
      this.$emit('close');
    },
    positive() {
      this.$emit('confirm');
    },
  },
}
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

button {
  width: 90px;
  height: 35px; /* Set button height */
  background-color: #f5f5f8; /* Set button background color */
  border: 1px solid #9b9b9b; /* Remove button border */
  border-radius: 3px;
  color: #9b9b9b; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

#positive-button {
  background-color: #7a66fb; /* Set button background color */
  color: white; /* Set button text color */
}

.input-form input[type="text"] {
  width: 100%;
  margin-bottom: 20px; /* adjust as needed */
  height: 35px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

.input-form textarea {
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 0;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font: inherit;
  font-weight: 400;
  font-size: 14px;
  resize: none;
}

.popup-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 376px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 38px 48px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* adjust as needed */
}

.button-group button {
  margin-left: 10px; /* adjust as needed */
}
</style>
<template>
  <div class="list-item-container">
    <div class="list-item">
      <span class="priority">{{ priorityText }}</span>
      <span class="message" :title="content.message">{{ content.message }}</span>
      <input type="checkbox" :id="`favorite-${content._id}`" class="favorite-checkbox" v-model="localFavorite"/>
      <label :for="`favorite-${content._id}`"></label>
    </div>
    <ActionButtons :enabled="(content.priority !== 6) && !(content.priority == 4 && content.tags.includes(1) && content.weather.value == 100)"
                   :callbacks="buttonCallbacks" />
  </div>
</template>

<script>
import ActionButtons from './ActionButtons.vue';
export default {
  name: 'SmallContentItemContainer',
  components: {
    ActionButtons
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      localFavorite: this.content.favorite || 0,
      priorityMap: {
        0: "",
        1: "",
        2: "VIP",
        3: "EPSD",
        4: "고정",
        5: "Guest",
        6: "미디어아트"
      }
    }
  },
  watch: {
    'content.favorite': function(newVal) {
      this.localFavorite = newVal;
    },
    localFavorite: function(newVal) {
      this.$emit('favorite', { favorite: newVal>0, id: this.content._id });
    }
  },
  computed: {
    priorityText() {
      return this.priorityMap[this.content.priority];
    },
    buttonCallbacks() {
      return {
        copy: this.copyContent,
        edit: this.editContent,
        delete: this.deleteContent
      }
    }
  },
  methods: {
    copyContent() {
      this.$emit('copy');
    },
    editContent() {
      this.$emit('edit');
    },
    deleteContent() {
      this.$emit('delete');
    }
  }
}
</script>

<style scoped>

.list-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 5px 10px;
  background-color: #e6e6e7;
  border-radius: 5px;
  gap: 10px;
}

.list-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.favorite-checkbox {
  display: none; /* Hide the checkbox */
}

.message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item span {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.favorite-checkbox + label {
  width: 17px;
  height: 16px;
  background-image: url('@/assets/cms/favorite_unchecked.svg');
  background-size: cover;
}

.favorite-checkbox:checked + label {
  background-image: url('@/assets/cms/favorite.svg');
}

.priority {
  width: 40px;
  max-width: 40px;
  background-color: #fff; /* White background color */
  border-radius: 3px; /* Set the corner radius to 5px for rounded corners */
  padding-left: 5px; /* Add left padding */
  padding-right: 5px; /* Add right padding */
  text-align: center;
  font-size: 15px; /* Set font size to 12px */
}
</style>
<style >
:root {
  --msg-max-width : 293.67px;
}
</style>

<template>
  <div class="list-item-container">
    <label :for="`select-${content._id+this.index}`"></label>
    <input type="checkbox" :id="`select-${content._id+this.index}`" class="select-checkbox" @change="onSelect" v-model="localIsSelected"/>
    <div class="list-item left" :class="{ selected: localIsSelected  }">
      <span class="message resized" :title="content.message">{{ content.message }}</span>
    </div>
    <div class="list-item mid" :class="{ selected: localIsSelected  }">
      <span class="priority">{{ priorityText }}</span>
      <div class="divider"/>
      <span class="repeat" v-if="!inPlaylist">{{ content.repeat }}회</span>
      <div class="divider" v-if="!inPlaylist"/>
      <span class="animation">{{ animationText }}</span>
      <div class="divider"/>
      <span class="font" :title="fontText">{{ fontText }}</span>
      <div class="divider"/>
      <input type="checkbox" :id="`favorite-${content._id}`" class="favorite-checkbox" v-model="localFavorite" disabled/>
      <label :for="`favorite-${content._id}`"></label>
    </div>
    <ActionButtons :callbacks="buttonCallbacks" :only-edit="true" :enabled="(content.priority !== 6) && !(content.priority == 4 && content.tags.includes(1) && content.weather.value == 100)"/>
  </div>
</template>

<script>
import ActionButtons from "@/components/cms/ActionButtons.vue";

export default {
  name: 'PlaylistContentItemContainer',
  components: {ActionButtons},
  props: {
    content: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    inPlaylist: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localFavorite: this.content.favorite || 0,
      localIsSelected: this.isSelected,
      priorityMap: {
        0: "",
        1: "",
        2: "VIP",
        3: "EPSD",
        4: "고정",
        5: "Guest",
        6: "미디어아트"
      },
      animationMap: {
        0: '페이드인',
        1: '페이드 아웃',
        2: '날아오기',
        3: '디졸브',
        4: '닦아내기',
        5: '트레이싱',
        6: '얼굴',
        7: '반전',
      },
      fontMap: {
        0: 'Arial',
        1: 'Calibri',
        2: 'Calibri Light',
        3: 'Elice DX Neolli Light',
        4: 'Galmuri14 Regular',
        5: 'GalmuriMono11 Regular',
        6: 'Georgia',
        7: 'Gulim',
        8: 'GulimChe',
        9: 'Mongolian Baiti',
        10: 'Noto Sans',
        11: 'Noto Sans KR',
        12: 'Noto Sans TC',
        13: 'Noto Sans JP',
        14: 'Noto Kufi Arabic',
        15: 'Open Sans',
        16: 'SansSerif',
        17: 'SUITE Light',
        18: 'Technic',
        19: 'Verdana',
      },
    }
  },
  created() {
  },
  watch: {
    'content.favorite': function(newVal) {
      this.localFavorite = newVal;
    },
    isSelected: {
      handler(newVal) {
        this.localIsSelected = newVal;
      },
      immediate: true
    },
    localIsSelected: function(newVal) {
      this.$emit('select', { selected: newVal, id: this.content._id, index: this.index });
    },
    localFavorite: function(newVal) {
      this.$emit('favorite', { favorite: newVal>0, id: this.content._id });
    }
  },
  computed: {
    priorityText() {
      return this.priorityMap[this.content.priority];
    },
    animationText() {
      if (!this.content.animation && this.content.animation !== 0) {
        return '-';
      }
      return this.animationMap[this.content.animation];
    },
    fontText() {
      if (!this.content.font && this.content.font !== 0) {
        return '-';
      }
      return this.fontMap[this.content.font];
    },
    buttonCallbacks() {
      return {
        copy: this.copyContent,
        edit: this.editContent,
        delete: this.deleteContent
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateMaxWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateMaxWidth);
  },
  methods: {
    onSelect(event) {
      const newVal = event.target.checked;
      this.localIsSelected = newVal;
      this.$emit('select', { selected: newVal, id: this.content._id, index: this.index });
    },
    copyContent() {
    },
    editContent() {
      this.$emit('edit');
    },
    deleteContent() {
    },
    updateMaxWidth() {
      const ellipsisContainer = this.$el.querySelector('.message');
      if (ellipsisContainer) {
        let width = this.$el.querySelector('.list-item').clientWidth - 20;
        document.documentElement.style.setProperty('--msg-max-width', `${width}px`);
      }
    }
  }
}
</script>

<style scoped>

span {
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button {
  width: 75px;
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
  margin-left: 15px;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  border: 2px solid #c4c4c6;
  gap: 10px;
}

.left {
  flex-grow: 1;
}

.mid {
  flex-shrink: 0;
}

.list-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

input[type="checkbox"] {
  border: 2px solid #bcb2fd; /* Set the border color to #7a66fc */
  border-radius: 3px; /* Set the border radius to 3px */
  appearance: none; /* Remove default checkbox appearance */
  -webkit-appearance: none; /* Remove default checkbox appearance for Webkit browsers */
  -moz-appearance: none; /* Remove default checkbox appearance for Mozilla browsers */
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative; /* Needed for pseudo-element positioning */
}

input[type="checkbox"]:checked {
  border: 2px solid #7a66fc; /* Set the border color to #7a66fc */
  background-color: #7a66fc; /* Set the background color to #7a66fc when checked */
}

.message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message.resized {
  max-width: var(--msg-max-width);
}

.favorite-checkbox {
  display: none; /* Hide the checkbox */
}

.list-item span {
  flex-grow: 1;
}

.favorite-checkbox + label {
  width: 17px;
  height: 16px;
  background-image: url('@/assets/cms/favorite_unchecked.svg');
  background-size: cover;
}

.favorite-checkbox:checked + label {
  background-image: url('@/assets/cms/favorite.svg');
}

.divider {
  min-width: 1px;
  max-width: 1px;
  height: 20px;
  border-left: 1px solid #9b9b9b;
}

.priority {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.animation {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.font {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.repeat {
  width: 40px;
  max-width: 40px;
  text-align: center;
}

.selected {
  border: 2px solid #7a66fc;
}
</style>
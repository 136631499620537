<template>
  <button type="button" class="icon-button" v-if="!onlyEdit" @click="callbacks.copy">
      <img src="@/assets/cms/content_copy.svg" alt="복사"/>
    </button>
    <button type="button" class="icon-button" @click="callbacks.edit" :disabled="!enabled">
      <img src="@/assets/cms/edit.svg" alt="수정"/>
    </button>
  <button type="button" class="icon-button" v-if="!onlyEdit" @click="callbacks.delete">
      <img src="@/assets/cms/delete.svg" alt="삭제"/>
    </button>
</template>

<script>
export default {
  name: 'ActionButtons',
  props: {
    callbacks: {
      type: Object,
      required: true
    },
    onlyEdit: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
button {
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

button:disabled {
  background-color: #c4c4c6;
  color: #686868;
  cursor: not-allowed;
}

.icon-button {
  display: flex;
  width: 27px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
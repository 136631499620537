<template>
  <div class="list-item-container">
    <input :id="`${idPrefix}-select-all`" type="checkbox" class="select-checkbox" v-model="localAllIsSelected" @change="toggleSelectAll"/>
    <label :id="`${idPrefix}-select-all`" for="select-all" >{{ localAllIsSelected? "전체 취소하기": "전체 선택하기" }}</label>
    <div class="list-item right">
      <span class="selected-count">{{ selectedCount.toString().padStart(2, '0') }}</span>
      <span>개 선택됨</span>
      <button type="button" style="margin-left: 30px;" @click="$emit('button-clicked')"> {{ buttonLabel }} </button>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    idPrefix: {
      type: String,
      required: true
    },
    allIsSelected: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    selectedCount: {
      type: Number,
      default: 0
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localAllIsSelected: this.allIsSelected,
      externalChange: false
    }
  },
  watch: {

    allIsSelected: {
      handler(newVal) {
        if (this.localAllIsSelected !== newVal) {
          this.externalChange = true;
        }
        this.localAllIsSelected = newVal;
      },
      immediate: true
    },
    localAllIsSelected(newVal) {
      if (this.externalChange) {
        this.externalChange = false;
        return;
      }
      this.$emit('select-all', newVal);
    },
    selectedCount(newVal) {
      if (newVal === 0) {
        this.localAllIsSelected = false;
      }
    }
  },
  methods: {
    toggleSelectAll() {
      this.$emit('select-all', this.localAllIsSelected);
    }
  }
}
</script>

<style scoped>

span {
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
  color: #686868;
}

button {
  width: 75px;
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
  margin-left: 15px;
}

.list-item {
  display: flex;
  align-items: center;
}

.right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.list-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

input[type="checkbox"] {
  border: 2px solid #bcb2fd; /* Set the border color to #7a66fc */
  border-radius: 3px; /* Set the border radius to 3px */
  appearance: none; /* Remove default checkbox appearance */
  -webkit-appearance: none; /* Remove default checkbox appearance for Webkit browsers */
  -moz-appearance: none; /* Remove default checkbox appearance for Mozilla browsers */
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative; /* Needed for pseudo-element positioning */
}

input[type="checkbox"]:checked {
  border: 2px solid #7a66fc; /* Set the border color to #7a66fc */
  background-color: #7a66fc; /* Set the background color to #7a66fc when checked */
}

.selected-count {
  color: #7a66fc;
}

</style>
<template>
  <div class="container">
    <div class="block">
      <h2>{{ path === 'content' ? 'CONTENT' : 'ep CONTENT' }}</h2>
      <div ref="previewWrap" class='preview-container'>
        <PreviewCanvas
            :ratioWidth="mediaWidth"
            :ratioHeight="mediaHeight"
            :duration="duration"
            :repeat="repeat"
            :message="message"
            :language="language"
            :variable="variable"
            :font="font"
            :font_size="font_size"
            :line_height="line_height"
            :letter_spacing="letter_spacing"
            :font_color="font_color"
            :background_color="background_color"
            :animation="animation"
            :animation_direction="animation_direction"
            :sections="sections"
            :image_name="image_name"
            :fonts="fonts"
            :font_colors="font_colors"
            :animations="animations"
            :animation_directions="animation_directions"
        >
        </PreviewCanvas>
      </div>
      <div class='checkbox-container'>
        <h3 style="grid-column: 1; grid-row: 1;" v-show="!isEPContent">필터</h3>
        <div class="checkbox-item" style="grid-column: 2; grid-row: 1;" v-show="!isEPContent">
          <input type="checkbox" id="priority-2" name="priority" @change="onPriorityChange(2, $event)">
          <label for="priority-2">VIP</label>
        </div>
        <div class="checkbox-item" style="grid-column: 3; grid-row: 1;" v-show="!isEPContent">
          <input type="checkbox" id="priority-4" name="priority" @change="onPriorityChange(4, $event)">
          <label for="priority-4">고정</label>
        </div>

        <h3 style="grid-column: 1; grid-row: 2;">태그</h3>
        <div class="checkbox-item" style="grid-column: 2; grid-row: 2;">
          <input type="checkbox" id="tag-1" name="tag" @change="onTagChange(1, $event)">
          <label for="tag-1">{{ tagNames[1] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 3; grid-row: 2;">
          <input type="checkbox" id="tag-2" name="tag" @change="onTagChange(2, $event)">
          <label for="tag-2">{{ tagNames[2] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 4; grid-row: 2;">
          <input type="checkbox" id="tag-3" name="tag" @change="onTagChange(3, $event)">
          <label for="tag-3">{{ tagNames[3] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 5; grid-row: 2;">
          <input type="checkbox" id="tag-4" name="tag" @change="onTagChange(4, $event)">
          <label for="tag-4">{{ tagNames[4] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 2; grid-row: 3;">
          <input type="checkbox" id="tag-5" name="tag" @change="onTagChange(5, $event)">
          <label for="tag-5">{{ tagNames[5] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 3; grid-row: 3;">
          <input type="checkbox" id="tag-6" name="tag" @change="onTagChange(6, $event)">
          <label for="tag-6">{{ tagNames[6] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 4; grid-row: 3;">
          <input type="checkbox" id="tag-7" name="tag" @change="onTagChange(7, $event)">
          <label for="tag-7">{{ tagNames[7] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 5; grid-row: 3;">
          <input type="checkbox" id="tag-8" name="tag" @change="onTagChange(8, $event)">
          <label for="tag-8">{{ tagNames[8] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 2; grid-row: 4;">
          <input type="checkbox" id="tag-9" name="tag" @change="onTagChange(9, $event)">
          <label for="tag-9">{{ tagNames[9] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 3; grid-row: 4;">
          <input type="checkbox" id="tag-10" name="tag" @change="onTagChange(10, $event)">
          <label for="tag-10">{{ tagNames[10] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 4; grid-row: 4;">
          <input type="checkbox" id="tag-11" name="tag" @change="onTagChange(11, $event)">
          <label for="tag-11">{{ tagNames[11] }}</label>
        </div>
        <div class="checkbox-item" style="grid-column: 5; grid-row: 4;">
          <input type="checkbox" id="tag-12" name="tag" @change="onTagChange(12, $event)">
          <label for="tag-12">{{ tagNames[12] }}</label>
        </div>
      </div>
      <div class='form-container'>
        <h4 style="grid-column: 1; grid-row: 1;">개별 콘텐츠 시간</h4>
        <input type="text" v-model.lazy="formattedDuration" id="duration" disabled="true"
               style="grid-column: 1; grid-row: 2;">
        <h4 style="grid-column: 2; grid-row: 1;" v-show="!isEPContent">반복</h4>
        <input type="text" v-model="repeat" id="repeat" inputmode="numeric" pattern="[0-9]*"
               style="grid-column: 2; grid-row: 2;" v-show="!isEPContent">
        <h4 style="grid-column: 3; grid-row: 1;" v-show="!isEPContent && isWeather">날씨</h4>
        <select v-model="selectedWeather" id="weather" v-show="!isEPContent && isWeather" @change="onChangeWeather"
                style="grid-column:3; grid-row: 2;">
          <option v-for="(weather, index) in weathers" :key="index" :value="weather.value">
            {{ weather.text }}
          </option>
        </select>
        <div class="divider" style="grid-row: 3;"/>
        <h4 style="grid-column: 1; grid-row: 4;">메시지</h4>
        <textarea v-model="currentMessage" id="message" :disabled="isImage"
                  style="grid-column-start: 1; grid-column-end: 3; grid-row-start: 5; grid-row-end: 7; padding: 5px; background: #e6e6e7; border: none; border-radius: 3px; resize: none; font-weight: 400; font-size: 14px;"></textarea>
        <p style="grid-column: 2; grid-row: 7; justify-self: end; color: #9b9b9b; padding-right: 10px;">
          {{ this.actualLength(currentMessage) }} / {{ this.messageMaxLength }}</p>
        <span style="grid-column-start: 1; grid-row: 7;" v-show="!isEPContent">%로 Section구분 (최대 Section 3개)<br>한 Section당 15자 이내로 작성<br>표현가능 글자 수(최적폰트사이즈10)<br>영문 - 12자 / 한글 - 7자 + 띄어쓰기2</span>

        <select v-model="language" id="language" :disabled="isImage"
                style="grid-column-start:3; grid-column-end: 5; grid-row: 5;">
          <option v-for="(language, index) in languages" :key="index" :value="language.value">{{
              language.text
            }}
          </option>
        </select>
        <select v-model="variable" id="variable" :disabled="isImage"
                style="grid-column-start:3; grid-column-end: 5; grid-row: 6;">
          <option v-for="(variable, index) in variables" :key="index" :value="variable.value">{{
              variable.text
            }}
          </option>
        </select>
        <div style="grid-column-start: 3;  grid-column-end: 5; grid-row: 7;" v-show="!isEPContent">
          <button @click="onAddImageClick"
                  style="display: inline-block; background-color: white; color: #7a66fb; border: 1px solid #7a66fb; width: auto">이미지 추가하기
          </button>
          <div v-if="showImages" class="images-container">
            <button @click="addImage" class="add-image-button" :disabled="isWeather">+</button>
            <div v-for="(image, index) in images" :key="index">
              <input type="checkbox" :id="`image-${index}`"
                     v-model="image.selected" class="image-checkbox" style="display: none;">
              <label :for="`image-${index}`">
                <div class="image-wrapper">
                  <img :src="image.src" class="image-item" :class="{ selected: image.selected }"
                       @click="selectImage(index)">
                  <div class="menu">
                    <button class="menu-button">⋯</button>
                    <button class="delete-button" @click="deleteImage(index)">✖</button>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <span style="display: inline-block; margin-left: 20px;">이미지는 134*20 사이즈만 추가 가능</span>
        </div>
        <div class="divider" style="grid-row: 8;"/>
        <h4 style="grid-column: 1; grid-row: 9;">Font
          <img src="@/assets/cms/guide.svg" alt="가이드" style="position: absolute" v-on:mouseover="showFontGuide = true"
               v-on:mouseout="showFontGuide = false"/>
          <div v-if="showFontGuide" class="guide-image">
            <img src="@/assets/cms/guide_font.png" alt="font guide"/>
          </div>
        </h4>
        <select v-model="font" id="font" :disabled="isImage"
                style="grid-column-start: 1; grid-column-end: 3;   grid-row: 10;" @change="fontSelectedManually = true">
          <option v-for="(font, index) in fonts" :key="index" :value="font.value">{{ font.text }}</option>
        </select>
        <div class="select-container" style="grid-column:3; grid-row: 10;">
          <img src="@/assets/cms/icon_font_size.svg" style="width: 34px; height: 34px;"/>
          <select v-model="font_size" id="font_size" :disabled="isImage">
            <option v-for="(font_size, index) in font_sizes" :key="index" :value=font_size>{{ font_size }}</option>
          </select>
        </div>
        <div class="select-container" style="grid-column:3; grid-row: 11;">
          <img src="@/assets/cms/icon_letter_spacing.svg" style="width: 34px; height: 34px;"/>
          <select v-model="line_height" id="line_height" :disabled="isImage">
            <option v-for="(line_height, index) in line_heights" :key="index" :value=line_height>{{
                line_height
              }}
            </option>
          </select>
        </div>
        <div class="select-container" style="grid-column:4; grid-row: 11;">
          <img src="@/assets/cms/icon_line_height.svg" style="width: 34px; height: 34px;"/>
          <select v-model="letter_spacing" id="letter_spacing" :disabled="isImage">
            <option v-for="(letter_spacing, index) in letter_spacings" :key="index" :value=letter_spacing>
              {{ letter_spacing }}
            </option>
          </select>
        </div>
        <div class="select-container" style="grid-column:3; grid-row: 12">
          <img src="@/assets/cms/icon_font_color.svg" style="width: 34px; height: 34px;"/>
          <select v-model="font_color" :disabled="isImage || fontColorDisabled"
                  :style="{ color: isFontColorDropdownOpen ? '' : font_color, backgroundColor: isFontColorDropdownOpen ? '' : font_color }"
                  @focus="isFontColorDropdownOpen = true"
                  @blur="isFontColorDropdownOpen = false">
            <option v-for="(color, index) in font_colors" :key="index" :value="color.code">{{ color.text }}</option>
          </select>
        </div>
        <div class="select-container" style="grid-column:4; grid-row: 12">
          <img src="@/assets/cms/icon_background_color.svg" style="width: 34px; height: 34px;"/>
          <select v-model="background_color" :disabled="isImage"
                  :style="{ color: isBackgroundColorDropdownOpen ? '' : background_color, backgroundColor: isBackgroundColorDropdownOpen ? '' : background_color }"
                  @focus="isBackgroundColorDropdownOpen = true"
                  @blur="isBackgroundColorDropdownOpen = false">
            <option v-for="(color, index) in background_colors" :key="index" :value="color.code">{{
                color.text
              }}
            </option>
          </select>
        </div>
        <div class="divider" style="grid-row: 13;"/>
        <h4 style="grid-column: 1; grid-row: 14;">애니메이션
          <img src="@/assets/cms/guide.svg" alt="가이드" style="position: absolute"
               v-on:mouseover="showAnimationGuide = true"
               v-on:mouseout="showAnimationGuide = false"/>
          <div v-if="showAnimationGuide" class="guide-image">
            <img src="@/assets/cms/guide_animation.png" alt="animation guide"/>
          </div>
        </h4>
        <select v-model="animation" id="animation" :disabled="isImage"
                style="grid-column-start: 1; grid-column-end: 3; grid-row: 15;" @change="onAnimationChange">
          <option v-for="(animation, index) in animations" :key="index" :value="animation.value">{{
              animation.text
            }}
          </option>
        </select>
        <select v-model="animation_direction" id="animation_direction"
                :disabled="isImage || !currentAnimation.direction"
                style="grid-column-start: 1; grid-column-end: 3; grid-row: 16;">
          <option v-for="(animation_direction, index) in animation_directions" :key="index"
                  :value="animation_direction.value">
            {{ animation_direction.text }}
          </option>
        </select>
        <h4 style="grid-column: 3; grid-row: 14;" v-show="!isEPContent">애니메이션 속도</h4>
        <div class="select-container" style="grid-column: 3; grid-row: 15;" v-show="!isEPContent">
          <h4 style="margin-right: 30px; white-space: nowrap;">Section 1</h4>
          <select v-model="sections[0]" id="sections-0" style="min-width: 130px;"
                  :disabled="!sectionEnabled[0]">
            <option v-for="(t, index) in section_times" :key="index"
                    :value="t">{{ t }}
            </option>
          </select>
          초
          <input type="checkbox" class="select-checkbox" v-model="sectionEnabled[0]" :disabled="!isImage && !currentAnimation.sections[maxPercentCount].enabled[0]"/>
<!--          <input type="checkbox" class="select-checkbox" v-model="sectionEnabled[0]" :disabled="!isImage && !currentAnimation.sections[maxPercentCount].enabled[0]"/>-->
        </div>
        <div class="select-container" style="grid-column: 3; grid-row: 16;" v-show="!isEPContent">
          <h4 style="margin-right: 30px; white-space: nowrap;">Section 2</h4>
          <select v-model="sections[1]" id="sections-1" style="min-width: 130px;"
                  :disabled="!sectionEnabled[1]">
            <option v-for="(t, index) in section_times" :key="index" :value="t">{{ t }}</option>
          </select>
          초
          <input type="checkbox" class="select-checkbox" v-model="sectionEnabled[1]" :disabled="isImage || !currentAnimation.sections[maxPercentCount].enabled[1]"/>
        </div>
        <div class="select-container" style="grid-column: 3; grid-row: 17;" v-show="!isEPContent">
          <h4 style="margin-right: 30px; white-space: nowrap;">Section 3</h4>
          <select v-model="sections[2]" id="sections-2" style="min-width: 130px;"
                  :disabled="!sectionEnabled[2]">
            <option v-for="(t, index) in section_times" :key="index" :value="t">{{ t }}</option>
          </select>
          초
          <input type="checkbox" class="select-checkbox" v-model="sectionEnabled[2]" :disabled="isImage || !currentAnimation.sections[maxPercentCount].enabled[2]"/>
        </div>
      </div>
      <div class="button-container">
        <button v-if="playlistId" @click="popupMessage = 'CONTENT를 다른 이름으로 저장하시겠습니까?'; saveAs = true; showPopup = true"
                style="background-color: #7a66fb; color: white; font-size: 12px; border: none;"
                v-html="'다른 이름으로<br>저장하기'"></button>
        <button
            @click="popupMessage = path === 'content' ? 'CONTENT를 저장하시겠습니까?' : 'ep CONTENT를 저장하시겠습니까?'; saveAs = false; showPopup = true"
            style="background-color: #7a66fb; color: white; border: none;">저장하기
        </button>
        <button @click="onReset">옵션 초기화</button>
        <button @click="onCancel">취소하기</button>
      </div>
    </div>
  </div>
  <ConfirmPopup :message="popupMessage" @confirm="onSave"
                @close="showPopup = false" v-if="showPopup"/>
</template>

<script>
import axios from 'axios';
import ConfirmPopup from "@/components/cms/ConfirmPopup.vue";
import PreviewCanvas from "@/components/cms/PreviewCanvas.vue";

export default {
  components: {PreviewCanvas, ConfirmPopup},
  data() {
    return {
      branch: 'YON',
      mediaWidth: 0,
      mediaHeight: 0,
      path: '',
      contentId: undefined,
      isFontColorDropdownOpen: false,
      isBackgroundColorDropdownOpen: false,
      weathers: [
        {value: 99, text: '기본'},
        {value: 0, text: '맑음'},
        {value: 1, text: '흐림'},
        {value: 2, text: '비'},
        {value: 3, text: '눈'},
        /*{value: 100, text: '자동'},*/
      ],
      languages: [
        {value: 'kr', text: '한국어'},
        {value: 'en', text: '영어'},
        {value: 'jp', text: '일본어'},
        {value: 'cn', text: '중국어'},
        {value: 'fr', text: '프랑스어'},
        {value: 'de', text: '독일어'},
        {value: 'es', text: '스페인어'},
        {value: 'it', text: '이탈리아어'},
        {value: 'ru', text: '러시아어'},
        {value: 'pt', text: '포르투갈어'},
        {value: 'ar', text: '아랍어'}
      ],
      // 닉네임, VIP명, VIP단체명
      variables: [
        {value: '', text: '변수'},
        {value: '[닉네임]', text: '닉네임'},
        {value: '[VIP명]', text: 'VIP명'},
        {value: '[VIP단체명]', text: 'VIP단체명'}
      ],
      fonts: [
        {value: 0, text: 'Arial'},
        {value: 1, text: 'Calibri'},
        {value: 2, text: 'Calibri Light'},
        {value: 3, text: 'Elice DX Neolli Light'},
        {value: 4, text: 'Galmuri14 Regular'},
        {value: 5, text: 'GalmuriMono11 Regular'},
        {value: 6, text: 'Georgia'},
        {value: 7, text: 'Gulim'},
        {value: 8, text: 'GulimChe'},
        {value: 9, text: 'Mongolian Baiti'},
        {value: 10, text: 'Noto Sans'},
        {value: 11, text: 'Noto Sans KR'},
        {value: 12, text: 'Noto Sans TC'},
        {value: 13, text: 'Noto Sans JP'},
        {value: 14, text: 'Noto Kufi Arabic'},
        {value: 15, text: 'Open Sans'},
        {value: 16, text: 'SansSerif'},
        {value: 17, text: 'SUITE Light'},
        {value: 18, text: 'Technic'},
        {value: 19, text: 'Verdana'},
      ],
      font_sizes: [6, 7, 8, 9, 10, 12, 14, 16, 18, 20],
      line_heights: [6, 7, 8, 9, 10, 11, 13, 15, 17, 19, 21, 23],
      letter_spacings: [80, 85, 90, 95, 100, 105, 110, 115, 120],
      font_colors: [
        {value: 0, text: '웜', code: '#ff8c00'},
        {value: 1, text: '흰색', code: '#ffffff'},
        {value: 2, text: '쿨', code: '#c5dbff'},
        {value: 3, text: '블랙', code: '#000000'},
      ],
      fontColorDisabled: false,
      animations: [
        {
          value: 3, text: '디졸브', direction: false, sectionLimit: 2, code:'dissolve',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [8, 8, 0]
            },
            2: {
              enabled: [true, true, true],
              duration: [4, 4, 8]
            }
          }
        },
        {
          value: 0, text: '페이드인', direction: false, sectionLimit: 2, code:'fadeIn',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [8, 8, 0]
            },
            2: {
              enabled: [true, true, true],
              duration: [4, 4, 8]
            }
          }
        },
        {
          value: 1, text: '페이드 아웃', direction: false, sectionLimit: 2, code:'fadeOut',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [8, 8, 0]
            },
            2: {
              enabled: [true, true, true],
              duration: [4, 4, 8]
            }
          }
        },
        {
          value: 4, text: '닦아내기', direction: false, sectionLimit: 2, code:'wipe',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [8, 8, 0]
            },
            2: {
              enabled: [true, true, true],
              duration: [4, 4, 8]
            }
          }
        },
        {
          value: 5, text: '트레이싱', direction: false, sectionLimit: 2, code:'trace',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [8, 8, 0]
            },
            2: {
              enabled: [true, true, true],
              duration: [4, 4, 8]
            }
          }
        },
        {
          value: 6, text: '얼굴', direction: false, sectionLimit: 1, code:'face',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [18, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [10, 8, 0]
            },
            2: {
              enabled: [false, false, false],
              duration: [18, 0, 0]
            }
          }
        },
        {
          value: 7, text: '반전', direction: false, sectionLimit: 1, code:'invert',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [18, 0, 0]
            },
            1: {
              enabled: [true, true, false],
              duration: [10, 8, 0]
            },
            2: {
              enabled: [false, false, false],
              duration: [18, 0, 0]
            }
          }
        },
        {
          value: 2, text: '날아오기', direction: true, sectionLimit: 0, code:'flying',
          sections: {
            0: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            1: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            },
            2: {
              enabled: [true, false, false],
              duration: [16, 0, 0]
            }
          }
        },
      ],
      animation_directions: [
        {value: 0, text: '오른쪽에서 왼쪽으로', code: 'rightToLeft'},
        {value: 1, text: '왼쪽에서 오른쪽으로', code: 'leftToRight'},
        {value: 2, text: '아래에서 위로', code: 'bottomToTop'},
        {value: 3, text: '위에서 아래로', code: 'topToBottom'},
      ],
      section_times: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      repeat: 1,
      message: '',
      /*weather_message: ['','','',''],*/
      selectedWeather: 99,
      weather: {value:99, text: '기본'},
      tempMessage: '',
      language: 'kr',
      font: 0,
      font_size: 10,
      line_height: 10,
      letter_spacing: 100,
      font_color: 1,
      background_color: 3,
      animation: 3,
      animation_direction: 0,
      prevPercentCount: 0,
      weather_prevPercentCount: [0, 0, 0, 0],
      sections: [16, 0, 0],
      sectionEnabled: [false, false, false],
      sectionsLoaded: false,
      priority: 4,
      tags: [],
      variable: '',
      showPopup: false,
      popupMessage: '',
      showImages: false,
      images: [],
      imagesLoaded: false,
      image_name: '',
      playlistId: this.$route.query.playlistId,
      saveAs: false,
      hasUnsavedChanges: false,
      showFontGuide: false,
      showAnimationGuide: false,
      tagNames: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
      },
      fontSelectedManually: false,
    };
  },
  mounted() {
    axios.get('/cms/tag/list')
        .then((response) => {
          const tempTags = response.data;
          this.tagNames = tempTags.reduce((acc, tag) => {
            acc[tag.index] = tag.name;
            return acc;
          }, {});
        })
        .catch((error) => {
          console.error(error);
        })
    this.path = this.$route.path.split('/')[1].toLowerCase()
    //if the path is /content/edit/:id, then get the id from the route params
    if (this.$route.path.split('/')[2].toLowerCase() === 'edit') {
      this.contentId = this.$route.params.contentId
      this.loadContent(this.contentId)
    } else {
      this.onReset()
    }
    //get media wall size
    this.getMediaSizeByBranch(this.branch);
  },
  watch: {
    variable(newVariable) {
      // Find the selected variable object
      const selectedVariable = this.variables.find(variable => variable.value === newVariable);

      // Append the selected variable text to the message
      this.currentMessage += selectedVariable.value;

      // Reset the variable to the first item
      this.variable = '';
    },
    repeat(newVal) {
      this.repeat = Math.max(1, newVal);
      this.hasUnsavedChanges = true;
    },
    language() {
      this.hasUnsavedChanges = true;
    },
    font() {
      this.hasUnsavedChanges = true;
    },
    font_size() {
      this.hasUnsavedChanges = true;
    },
    line_height() {
      this.hasUnsavedChanges = true;
    },
    letter_spacing() {
      this.hasUnsavedChanges = true;
    },
    font_color() {
      this.hasUnsavedChanges = true;
    },
    background_color() {
      this.hasUnsavedChanges = true;
    },
    animation() {
      this.sections = structuredClone(this.currentAnimation.sections[this.maxPercentCount].duration);
      this.hasUnsavedChanges = true;
    },
    animation_direction() {
      this.hasUnsavedChanges = true;
    },
    sections: {
      handler() {
        this.hasUnsavedChanges = true;
      },
      deep: true
    },
    weather_sections: {
      handler() {
        this.hasUnsavedChanges = true;
      },
      deep: true
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      if (window.confirm('저장하지 않은 변경 사항이 있습니다. 정말로 이동하시겠습니까?')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    actualLength(message) {
      if(!message) return;
      // replace %, [, ] with empty string
      return message.replace(/%|\[|\]/g, '').length
    },
    loadContent(contentId) {
      axios.get(`/cms/content/load/${contentId}`)
          .then(response => {
            Object.assign(this.$data, response.data);
            if (this.priority === 2) document.getElementById('priority-2').checked = true
            else if (this.priority === 4) document.getElementById('priority-4').checked = true
            this.tags.forEach(tagIndex => document.getElementById(`tag-${tagIndex}`).checked = true)
            this.fontSelectedManually = true;
            this.sectionsLoaded = true;
            this.selectedWeather = this.$data.weather.value;
          })
          .catch(error => {
            console.error(error);
          });
    },
    getMediaSizeByBranch(branchCode){
      axios.get(`/cms/branchInfo/${branchCode}`)
          .then(response => {
            this.branch = response.data;
            if(this.branch.mediaWidth){
              this.mediaWidth = this.branch.mediaWidth;
            }
            if(this.branch.mediaHeight){
              this.mediaHeight = this.branch.mediaHeight;
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    onPriorityChange(priority, event) {
      let myCheckbox = document.getElementsByName("priority");
      Array.prototype.forEach.call(myCheckbox, function (el) {
        el.checked = false;
      });
      event.target.checked = true;
      this.priority = priority;
      this.hasUnsavedChanges = true;
    },
    onTagChange(index, event) {
      if (event.target.checked) {
        this.tags.push(index);
      } else {
        this.tags = this.tags.filter(tagIndex => tagIndex !== index);
      }
      this.hasUnsavedChanges = true;
    },
    loadImages() {
      axios.get('/cms/content/image/load')
          .then(response => {
            response.data.map(image => {
              image = `/images/${image}`
              let found = this.images.find(img => img.src === image);
              if (!found) this.images.push({src: image, selected: false});
            });
          })
          .catch(error => {
            console.error(error);
          });
    },
    onAddImageClick() {
      if (!this.imagesLoaded) {
        this.loadImages();
        this.imagesLoaded = true;
      }
      this.showImages = !this.showImages;
    },
    addImage() {
      // open file dialog
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        const img = new Image();
        img.onload = () => {
          if (img.width !== 134 || img.height !== 20) {
            alert('이미지 크기는 134 x 20 만 가능합니다.');
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            // Create a FormData instance
            const formData = new FormData();
            // Append the file to the FormData instance
            formData.append('image', file);
            // Send a POST request to the /cms/content/image/add endpoint
            axios.post('/cms/content/image/add', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
                .then(response => {
                  response.data.map(image => {
                    image = `/images/${image}`
                    let found = this.images.find(img => img.src === image);
                    if (!found) this.images.push({src: image, selected: false});
                  });
                })
                .catch(error => {
                  console.error(error);
                });
          };
          reader.readAsDataURL(file);
        };
        img.src = url;
      };
      input.click();
    },
    selectImage(index) {
      if (this.images[index].selected) {
        this.message = this.tempMessage;
        this.image_name = '';
      } else {
        this.tempMessage = this.message;
        this.image_name = this.images[index].src.split('/').pop();
        this.message = '[이미지]' + this.image_name;
      }

      // Unselect all other images
      this.images.forEach((image, i) => {
        if (i !== index) {
          this.images[i] = {...this.images[i], selected: false}
        }
      });

      // Toggle the selected state of the clicked image
      try {
        this.$set(this.images[index], 'selected', !this.images[index].selected);
      } catch (e) {
        console.log(index);
      }
    },
    deleteImage(index) {
      const filename = this.images[index].src.split('/').pop();
      axios.post('/cms/content/image/delete', {filename})
          .then(() => {
            this.images.splice(index, 1);
          })
          .catch(error => {
            console.error(error);
          });
    },
    getWeather() {
      let values = this.weathers.map(function(obj) { return obj.value });
      let index = values.indexOf(this.selectedWeather);
      return this.weathers[index];
    },
    onChangeWeather(){
      this.weather = this.getWeather();
    },
    onAnimationChange(){
      //if animation is either 얼굴 or 반전, set the font color to #ffffff and disable the font color dropdown
      if(this.animation === 6 || this.animation === 7){
        this.font_color = '#ffffff';
        this.fontColorDisabled = true;
      }
      this.sectionEnabled[0] = false;
      this.sectionEnabled[1] = false;
      this.sectionEnabled[2] = false;
    },
    onCancel() {
      this.hasUnsavedChanges = false;
      this.$router.go(-1);
    },
    onReset() {
      // Reset all data properties to their initial values
      this.isFontColorDropdownOpen = false;
      this.isBackgroundColorDropdownOpen = false;
      this.duration = 13;
      this.repeat = 1;
      this.message = '';
      /*this.weather_message = ['', '', '', ''];*/
      this.weather = {value:99, text:'기본'},
      this.language = 'kr';
      this.font = '';
      this.font_size = 10;
      this.line_height = 10;
      this.letter_spacing = 100;
      this.font_color = '#ffffff';
      this.background_color = '#000000';
      this.animation = 3;
      this.animation_direction = 0;
      this.sections = [16, 0, 0];
      // Reset checkboxes
      let checkboxes = document.querySelectorAll("input[type='checkbox']");
      checkboxes.forEach(checkbox => checkbox.checked = false);
      if (this.path === 'epcontent') this.priority = 3;
      else {
        this.priority = 4;
        document.getElementById('priority-4').checked = true
      }
      this.tags = [];
      this.showImages = false;
      this.images = [];
      this.imagesLoaded = false;
      this.image_name = '';
    },
    onSave() {
      // Collect all data properties into a JSON object
      let data = {
        id: this.contentId,
        duration: this.duration,
        repeat: this.repeat === '' ? 1 : this.repeat,
        /*message: this.isWeather? this.weather_message.join('/') : this.message,
        weather_message: this.weather_message,*/
        message: this.message,
        weather: this.weather,
        language: this.language,
        font: this.font,
        font_size: this.font_size,
        line_height: this.line_height,
        letter_spacing: this.letter_spacing,
        font_color: this.font_color,
        background_color: this.background_color,
        animation: this.animation,
        animation_direction: this.animation_direction,
        sections: this.sections,
        priority: this.priority,
        tags: this.tags,
        image_name: this.image_name,
      };

      // Validate the data
      if (!this.validateData(data)) {
        return;
      }
      const tempData = data;
      // Make the POST request
      if (!this.saveAs) {
        axios.post('/cms/content/save', tempData)
            .then(() => {
              this.hasUnsavedChanges = false;
              this.$router.go(-1);
            })
            .catch(error => {
              // Handle the error here
              console.error(error);
            });
      } else {
        tempData.playlistId = this.playlistId;
        axios.post('/cms/content/saveAs', tempData)
            .then(() => {
              this.hasUnsavedChanges = false;
              this.$router.go(-1);
            })
            .catch(error => {
              // Handle the error here
              console.error(error);
            });
      }
    },
    validateData(data) {
      // Check if the message is not empty
      if (!data.message) {
        alert('메시지를 입력해주세요');
        return false;
      }

      // Check if the duration is a positive number
      if (data.duration <= 0) {
        alert('시간은 0보다 커야합니다');
        return false;
      }

      // Check if there are three or more "%" characters in the message
      if ((data.message.match(/%/g) || []).length >= 3) {
        alert('메시지에 "%" 문자가 세 개 이상 있습니다.');
        return false;
      }
      return true;
    },
  },
  computed: {
    isEPContent() {
      return this.path === 'epcontent';
    },
    isImage() {
      return this.images.filter(image => image.selected).length > 0;
    },
    percentCount() {
      if(!this.currentMessage) return 0;
      return (this.currentMessage.match(/%/g) || []).length;
    },
    maxPercentCount() {
      //if(this.isWeather) return this.weather_message.map(message => message? (message.match(/%/g) || []).length : '').reduce((acc, count) => Math.max(acc, count), 0);
      return this.percentCount;
    },
    currentPrevPercentCount: {
      get() {
        //if (this.isWeather) return this.weather_prevPercentCount[this.weather];
        return this.prevPercentCount;
      },
      set(newValue) {
        //if (this.isWeather) this.weather_prevPercentCount[this.weather] = newValue;
        this.prevPercentCount = newValue;
      },
    },
    isWeather() {
      return this.tags.includes(1);
    },
    currentMessage: {
      get() {
        //if (this.isWeather) return this.weather_message[this.weather];
        return this.message;
      },
      set(newVal) {
        if (!this.fontSelectedManually) {
          let index = 0;
          let firstChar = newVal.charAt(index);
          while (index < newVal.length) {
            if (/[가-힣]/.test(firstChar)) { // if the first character is Korean
              this.font = 3; // 'Elice DX Neolli Light'
              break;
            } else if (/[a-zA-Z]/.test(firstChar)) { // if the first character is English
              this.font = 5; // 'GalmuriMono11 Regular'
              break;
            } else { // if the first character is neither Korean nor English
              index++;
              firstChar = newVal.charAt(index);
            }
          }
        }
        if (this.isEPContent) {
          let temp = newVal.replace(/%/g, '');
          temp = temp.slice(0, this.messageMaxLength);
          /*if (this.isWeather) this.weather_message[this.weather] = temp
          else this.message = temp;*/
          this.message = temp;
          return;
        }


        // % 문자 개수를 계산
        const percentCount = (newVal.match(/%/g) || []).length;
        this.percentCount = percentCount;

        // if the percentCount is greater than 2, then remove all except the first 2
        if (this.percentCount > this.currentAnimation.sectionLimit) {
          newVal = newVal.replace(/%$/, '');
        }

        if (this.currentPrevPercentCount !== this.percentCount) {
          this.currentPrevPercentCount = this.percentCount;
          if (!this.sectionsLoaded) {
            this.sections = structuredClone(this.currentAnimation.sections[this.maxPercentCount].duration);
          }
        }

        // 섹션 개수에 따라 애니메이션 속도 섹션 활성화 또는 비활성화
        this.sections[0] = this.currentAnimation.sections[percentCount].duration[0];
        this.sections[1] = this.currentAnimation.sections[percentCount].duration[1];
        this.sections[2] = this.currentAnimation.sections[percentCount].duration[2];
        if(this.sections[0] === 0) {
          this.sectionEnabled[0] = false;
        }
        if(this.sections[1] === 0) {
          this.sectionEnabled[1] = false;
        }
        if(this.sections[2] === 0) {
          this.sectionEnabled[2] = false;
        }

        // Enable or disable the Section 2 and Section 3 selectors based on the count
        this.messageMaxLength = Math.min(15 * (this.percentCount + 1), 45);
        if (this.actualLength(newVal) > this.messageMaxLength) {
          newVal = newVal.slice(0, this.messageMaxLength);
        }

        this.sectionsLoaded = false;
        this.hasUnsavedChanges = true;
        //if (this.isWeather) this.weather_message[this.weather] = newVal;
        this.message = newVal;
      },
    },
    messageMaxLength() {
      return Math.min(15 * (this.percentCount + 1), 45);
    },
    duration: {
      get() {
        return this.sections.reduce((acc, section) => acc + section, 0);
      },
      set() {
      },
    },
    formattedDuration: {
      get() {
        return this.duration + '초';
      },
      set(newValue) {
        this.duration = Number(newValue.replace('초', ''));
      },
    },
    currentAnimation() {
      return this.animations.find(animation => animation.value === this.animation);
    },
  },
};
</script>

<style scoped>
h3 {
  margin-top: 0;
}

h4 {
  margin-top: 0; /* Remove the default margin */
  margin-bottom: 0; /* Remove the default margin */
}

input[type="text"] {
  flex-grow: 1;
  height: 35px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

input[type="checkbox"] {
  border: 2px solid #bcb2fd; /* Set the border color to #7a66fc */
  border-radius: 3px; /* Set the border radius to 3px */
  appearance: none; /* Remove default checkbox appearance */
  -webkit-appearance: none; /* Remove default checkbox appearance for Webkit browsers */
  -moz-appearance: none; /* Remove default checkbox appearance for Mozilla browsers */
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative; /* Needed for pseudo-element positioning */
}

input[type="checkbox"]:checked {
  border: 2px solid #7a66fc; /* Set the border color to #7a66fc */
  background-color: #7a66fc; /* Set the background color to #7a66fc when checked */
}

select {
  flex-grow: 1;
  min-height: 35px;
  max-width: 300px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

button {
  width: 90px;
  height: 35px; /* Set button height */
  background-color: #f5f5f8; /* Set button background color */
  border: 1px solid #9b9b9b; /* Remove button border */
  border-radius: 3px;
  color: #9b9b9b; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

span {
  display: block;
  font-size: 12px;
  color: #9b9b9b;
}

.container {
  display: grid;
  gap: 20px;
  padding-bottom: 50px;
}

.block {
  display: grid;
  gap: 10px;
  grid-template-columns: 3fr 2fr;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 10px 20px 30px 20px;
}

.preview-container {
  grid-row: 2;
  grid-column: 1;
  /*background-color: #9b9b9b;*/
  height: 32vw;
}

.checkbox-container {
  grid-row: 3;
  grid-column: 1;
  display: grid; /* Change to grid layout */
  gap: 10px;
  grid-template-rows: repeat(4, minmax(42px, auto)); /* Divide the top-bar into two columns */
  grid-template-columns: repeat(5, 128px); /* Divide the top-bar into two columns */
  align-items: center; /* Center items vertically */
}

.form-container {
  padding: 5px;
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  gap: 10px;
}

.button-container {
  grid-row: 4;
  grid-column: 2;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container input[type="checkbox"]:checked ~ label {
  background-color: #7a66fc;
  color: white;
}

.checkbox-container label {
  border: 2px solid #7a66fc; /* Set the border color to #7a66fc */
  border-radius: 3px; /* Set the border radius to 3px */
  font-weight: 500; /* Set the font weight to 500 */
  width: 100%;
  height: 42px;
  align-content: center;
  text-align: center;
  color: black;
  line-height: 15px;
}

.checkbox-container label:checked {
  background-color: #7a66fc;
}

.checkbox-item {
  display: flex; /* Allow elements to sit side-by-side */
  align-items: center; /* Center items vertically */
}

.divider {
  grid-column-start: 1;
  grid-column-end: 5;
  border-top: 1px solid #9b9b9b;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.images-container {
  position: absolute;
  z-index: 6;
  width: 290px;
  max-height: 151px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
  border: 2px solid #9b9b9b;
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(4, 63px);
  gap: 4px;
}

.images-container::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
  height: 8px; /* Adjust height as desired for horizontal scrollbars */
}

.images-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.images-container::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.images-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Thumb hover color */
}

.add-image-button {
  width: 63px;
  height: 63px;
  border-radius: 0;
  background-color: #b3b3b6;
  cursor: pointer;
  color: #e6e6e7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.image-item {
  width: 63px;
  height: 63px;
  background-color: #b3b3b6;
  cursor: pointer;
}

.selected {
  width: 59px;
  height: 59px;
  border: 2px solid #7a66fc;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.menu-button {
  background-color: transparent; /* Transparent background */
  padding: 0; /* Remove padding */
  width: 20px;
  height: 10px;
  color: #db304a; /* Black text */
  border: none; /* Remove border */
  cursor: pointer; /* Indicate clickable behavior */
}

.delete-button {
  display: none; /* Initially hidden */
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  background-color: #db304a; /* Red background */
  color: #ffffff; /* White text */
  border: none; /* Remove border */
  cursor: pointer; /* Indicate clickable behavior */
}

.menu:hover .delete-button {
  display: block; /* Show on hover */
}

.guide-image {
  position: absolute;
  right: 20px;
  z-index: 10;
}
</style>
<template>
  <div class="container" >
    <ContentContainer
        itemContainer="PlaylistContentItemContainer"
        title="PLAYLIST"
        path="content"
        :itemsPerPage="12"
        group="playlistGroup"
        :showCreateButton="false"
        :only-priority4="true"
        @add-selected="addContentsToPlaylist"
    />
    <PlaylistContentContainer
        group="playlistGroup"
        ref="playlistContentContainer"
    />
  </div>
</template>

<script>
import ContentContainer from './ContentContainer.vue';
import PlaylistContentContainer from "@/components/cms/PlaylistContentContainer.vue";

export default {
  components: {
    PlaylistContentContainer,
    ContentContainer,
  },
  data() {
    return {
      playlistId: undefined,
    };
  },
  created() {
    this.playlistId = this.$route.params.playlistId
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.playlistContentContainer.hasContentsChanged()) {
      if (window.confirm('저장하지 않은 변경 사항이 있습니다. 정말로 이동하시겠습니까?')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    addContentsToPlaylist(contents) {
      this.$refs.playlistContentContainer.addContents(contents);
    },
  },
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Split the container into two columns */
  gap: 20px; /* Add a 20px gap between the columns */
}
</style>
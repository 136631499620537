<template>
  <div id="app">
    <div class="full-width top-container">
      <div class="left">
        <span class="flex bold" style="font-size: 16px; margin-left: 20px;">현재 시간 : {{ now }}</span>
        <span class="flex bold" style="font-size: 12px; margin-left: 32px;">현재 재생 중인 타임라인</span>
        <input class="flex" style="margin-left: 12px; height: 21px; width: 138px;" type="text" disabled v-model="activeTimelineName">
        <span class="flex bold" style="font-size: 12px; margin-left: 32px;">현재 사이트</span>
        <select class="flex" style="margin-left: 12px; height: 27px; width: 146px;">
          <option v-for="site in sites" :key="site.value" :value="site.value">{{ site.name }}</option>
        </select>
        <button @click="openLogDialog">로그 보기</button>
      </div>
      <div class="mid" :hidden="isLoginPage">
        <div class="tabs">
          <ul class="tab-list">
            <li v-for="tab in tabs" :key="tab.title">
              <router-link :to="`/${tab.route}`" >{{ tab.title }}</router-link>
              <span class="line" v-bind:style="{ display: tab.title === activeTab ? 'block' : 'none' }"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="full-width">
      <router-view :activeTimeline="activeTimeline"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      now: '',
      activeTab: 'TIMELINE',
      tabs: [
        {
          title: 'TIMELINE',
          route: 'timeline'
        }, {
          title: 'PLAYLIST',
          route: 'playlist'
        }, {
          title: 'CONTENT',
          route: 'content/list'
        }, {
          title: 'ep CONTENT',
          route: 'epcontent/list'
        }, {
          title: '태그수정하기',
          route: 'tag'
        }
      ],
      activeTimeline: undefined,
      activeTimelineName: '',
      sites: [
        {name: '에피소드 용산', value: 1},
        {name: '사이트 1', value: 2},
        {name: '사이트 2', value: 3},
        {name: '사이트 3', value: 4},
        {name: '사이트 4', value: 5},
        {name: '사이트 5', value: 6},
        {name: '사이트 6', value: 7},
        {name: '사이트 7', value: 8},
        {name: '사이트 8', value: 9},
        {name: '사이트 9', value: 10},
      ]
    };
  },
  created() {
    setInterval(this.updateTime, 1000) // Update every 1 second
  },
  mounted() {
    this.checkPath()
  },
  watch: {
    '$route': 'checkPath'
  },
  methods: {
    setActiveTab(title) {
      if (this.isLoginPage){
        console.log('This is LoginPage')
        return;
      }
      this.activeTab = title;
    },
    checkPath(to, from) {
      if (to) console.log('to:', to.name, 'from:', from.name)
      let path = this.$route.path.split('/')[1].toLowerCase()

      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].route.startsWith(path)) {
          this.activeTab = this.tabs[i].title
          break
        }
      }
    },
    updateTime() {
      this.now = new Date().toLocaleString(
          'ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            weekday: 'short',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }
      );
      if(!this.activeTimeline|| this.now.endsWith('01')) {
        axios.get('/api/activeTimeline')
            .then(response => {
              this.activeTimeline = response.data
              this.activeTimelineName = response.data? response.data.name : ''
            })
            .catch(error => {
              console.error(error)
            })
      }
    },
    openLogDialog() {
      window.open('/log', '_blank')
    }
  },
  computed: {
    isLoginPage() {
      return this.$route.path === '/login'
    },
  },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

/* Set the font family to 'Noto Sans KR' */
#app {
  background-color: #f5f5f8; /* Light gray background color */
  font-family: 'Noto Sans KR', sans-serif;
}

button {
  width: 90px;
  height: 27px; /* Set button height */
  background-color: #f5f5f8; /* Set button background color */
  border: 1px solid #9b9b9b; /* Remove button border */
  border-radius: 3px;
  color: #9b9b9b; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
  margin-left: 30px; /* Add padding to the left of the button text */
}

/* Style the bold text */
.bold {
  font-weight: 700; /* Set the font weight to 700 for bold text */
}

.full-width {
  width: 100%; /* Stretch the top-bar to fill the width*/
  min-width: 1420px; /* Set a minimum width for the top-bar */
}

/* Style the top div */
.top-container {
  height: 57px; /* Set the height of the top-bar */
  display: grid; /* Change to grid layout */
  grid-template-columns: 6fr 4fr; /* Divide the top-bar into two columns */
}

.left {
  display: flex; /* Change to flexbox for horizontal layout */
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Stretch items vertically */
}

.mid {
  /* Set the width of the right div to wrap content */
  width: 100%;
}

.tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Noto Sans KR', sans-serif;
}

.tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* Added justify-content: space-between to distribute space evenly */
  justify-content: space-between;
}

.tab-list li {
  padding: 10px 15px;
  position: relative; /* Needed for underline positioning */
}

.tab-list a {
  display: block;
  text-decoration: none;
  color: #333; /* Set default color for inactive text */
  font-weight: 400; /* Set font weight for inactive text */
}

.line {
  bottom: 0; /* Place underline at the bottom of the link */
  left: 0; /* Align underline with the left edge of the link */
  width: 100%; /* Stretch underline to full width of the link */
  height: 2px; /* Set underline thickness */
  background-color: #333; /* Underline color */
}

.tab-list li.active .line { /* Style for active tab underline */
  opacity: 1; /* Make the underline visible */
}

.tab-list a.router-link-active {
  font-weight: 700;
}

</style>

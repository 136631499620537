<template>
  <td class="list-item-container"
      :style="{ position: 'absolute', left: '0', top: `${timePercentage}%` }">
    <div class="list-item" :style="{ height: `${height}px` }">
      <div class="header">
        <a @click="edit">{{ formatTime(start) }} - {{ formatTime(end) }}</a>
        <div class="message-container">
          <p class="title">{{ content.message }}</p>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      required: true
    },
    hourHeight: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    timePercentage() {
      return this.start * 10 / 8820;
    },
    height() {
      return (this.end - this.start) * this.hourHeight / 3600 - 4;
    }
  },
  methods: {
    formatTime(value) {
      let hours = Math.floor(value / 3600);
      let minutes = Math.floor((value % 3600) / 60);

      // Pad the values to two digits
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');

      // Return the formatted time
      return `${hours}:${minutes}`;
    },
    edit() {
      if (!this.editable) return;
      this.$emit('edit', this.index);
    },
  }
}
</script>

<style scoped>

.list-item {
  padding: 0 10px;
  margin: 0 15px;
  background-color: #e6e6e7;
  border-radius: 5px;
  color: black; /* Set the default text color to black */
  overflow: hidden;
}

.header {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 35px auto;
  /*align-content: center;*/
}

.list-item-container {
  gap: 5px;
  padding: 2px;
}

.list-item a {
  margin: auto 0;
  cursor: pointer;
}

.list-item p {
  font-size: 14px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.message-container::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
  height: 8px; /* Adjust height as desired for horizontal scrollbars */
}

.message-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.message-container::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.message-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Thumb hover color */
}
</style>
<template>
  <div class="container">
    <TagContainer />
  </div>
</template>

<script>
import TagContainer from "@/components/cms/TagContainer.vue";

export default {
  components: {
    TagContainer,
  },
  data() {
    return {
    };
  },
}
</script>

<style scoped>
.container {
  display: grid;
  gap: 20px; /* Add a 20px gap between the columns */
}
</style>
<template>
  <div class="pagination" style="margin-top: 10px;">
    <div class="button-nav">
      <button type="button" class="page-button" @click="goToFirstPage" v-if="currentPage > 1">
        <img src="@/assets/cms/page_front.svg" alt="시작"/>
      </button>
      <button type="button" class="page-button" @click="goToPreviousPage" v-if="currentPage > 1">
        <img src="@/assets/cms/page_left.svg" alt="앞"/>
      </button>
      <button type="button" class="page-button" @click="changePage(page)" v-for="page in totalPages" :key="page"
              :class="{ 'current-page': currentPage === page }">
        {{ page }}
      </button>
      <button type="button" class="page-button" @click="goToNextPage" v-if="currentPage < totalPages">
        <img src="@/assets/cms/page_right.svg" alt="뒤"/>
      </button>
      <button type="button" class="page-button" @click="goToLastPage" v-if="currentPage < totalPages">
        <img src="@/assets/cms/page_back.svg" alt="끝"/>
      </button>
    </div>
    <div class="custom-nav" v-if="usePageParam">
      <input type="number" v-model="customPage" min="1" :max="totalPages"/>
      <span>/ {{ totalPages }}</span>
      <button type="button" @click="changePage(customPage)">이동하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListPagination',
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    usePageParam: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      customPage: 1,
    };
  },
  watch: {
    currentPage(newPage) {
      this.customPage = newPage;
    },
    customPage(newPage) {
      if (newPage < 1) {
        this.customPage = 1;
      } else if (newPage > this.totalPages) {
        this.customPage = this.totalPages;
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },
  methods: {
    changePage(page) {
      this.$emit('page-changed', page);

      if (this.usePageParam) {
        const url = new URL(window.location);
        let query = {}
        url.searchParams.entries().forEach(([key, value]) => {
          query[key] = value;
        });
        query.p = page;
        this.$router.push({path: this.$route.path, query: query});
      }
    },
    goToFirstPage() {
      // this.currentPage = 1;
      // this.$emit('page-changed', 1);
      this.changePage(1);
    },
    goToPreviousPage() {
      // this.currentPage = this.currentPage - 1;
      // this.$emit('page-changed', -1);
      this.changePage(this.currentPage - 1);
    },
    goToNextPage() {
      // this.currentPage = this.currentPage + 1;
      // this.$emit('page-changed', -2);
      this.changePage(this.currentPage + 1);
    },
    goToLastPage() {
      // this.currentPage = this.totalPages;
      // this.$emit('page-changed', -3);
      this.changePage(this.totalPages);
    },
  }
}
</script>

<style scoped>

button {
  width: auto; /* Set button width */
  height: 27px; /* Set button height */
  background-color: white; /* Set button background color */
  border: 1px solid #7a66fb; /* Remove button border */
  border-radius: 3px;
  color: #7a66fb; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

input[type="number"] {
  flex-grow: 1;
  width: 30px;
  height: 25px;
  padding: 0 3px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

span{
  margin-left: 15px;
  margin-right: 15px;
}

.pagination {
  display: flex; /* Allow elements to sit side-by-side */
  flex-direction: column;
  justify-content: center; /* Center the buttons */
  align-items: center; /* Center the buttons */
  gap: 5px; /* Add a 5px gap between the buttons */
}

.page-button {
  background: none; /* No background */
  border: none; /* No border */
  color: #9b9b9b; /* Inactive color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px;
}

.current-page {
  font-weight: 700;
  color: black;
}

.page-button:active, .page-button.active {
  color: #7a66fc; /* Active color */
}

.page-button svg {
  fill: #9b9b9b; /* Inactive color for SVG */
}

.page-button:active svg, .page-button.active svg {
  fill: #7a66fc; /* Active color for SVG */
}
</style>
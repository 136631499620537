<template>
  <div>
    <h5>날짜 조회</h5>
    <div class="date-picker-container">
      <VueDatePicker
          v-model="date"
          :format="format"
          :auto-apply="true"
          :enable-time-picker="false"
          @cleared="date = ''; onButtonClick()"
      />
      <button type="button" @click="onButtonClick">조회하기</button>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      date: '',
      format: 'yy/MM/dd'
    }
  },
  methods: {
    onButtonClick() {
      this.$emit('dateSelected', this.date);
    }
  }
}
</script>

<style scoped>

h5 {
  margin: 0 0 5px 0;
}

button {
  white-space: nowrap;
  background-color: #7a66fb; /* Set button background color */
  padding: 0 10px; /* Add padding to the button */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

.date-picker-container {
  display: inline-grid;
  margin-right: 10px;
  grid-template-columns: 140px 80px;
  gap: 10px;
}

</style>

<template>
  <div class="search-box">
    <input type="text" v-model="searchText" @input="search" placeholder="검색어"/>
<!--    <button type="button" @click="search"> 검색하기</button>-->
    <button type="button" class="right-aligned" @click="createContent" v-if="showCreateButton">만들기</button>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  props: {
    showCreateButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchText: '',
    };
  },
  methods: {
    createContent() {
      this.$emit('create');
    },
    search() {
      this.$emit('search', this.searchText);
    },
  },
}
</script>

<style scoped>

button {
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

.right-aligned {
  margin-left: auto; /* Alternatively, use margin-left: auto for right alignment */
}
/* Style the search box container */
.search-box {
  display: flex; /* Allow elements to sit side-by-side */
  justify-content: space-between; /* Right-align the last button */
}

/* Style the input field */
.search-box input {
  margin-right: 5px; /* Add a margin between input and button */
  width: 280px; /* Set button width */
  height: 25px; /* Set button height */
  padding: 0 10px 0 10px; /* Add padding inside the input */
  border: 1px solid #9b9b9b; /* Add a border around the input */
  border-radius: 3px;
  font-size: 12px; /* Set button text size */
  font-weight: 400; /* Set button text weight */
}

/* Style the search button */
.search-box button {
  width: 75px; /* Set button width */
}
</style>
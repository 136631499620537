<template>
  <td class="list-item-container">
    <img :src="localImage" alt="thumbnail" class="thumbnail" />
    <div class="list-item" >
      <a @click="navigateToEdit">{{ playlist.title }}</a>
      <input type="checkbox" :id="`favorite-${playlist._id}`" class="favorite-checkbox" v-model="localFavorite"/>
      <label :for="`favorite-${playlist._id}`"></label>
    </div>
    <component :is="small ? 'ActionButtons' : 'PlaylistButtons'" :enabled="true" :callbacks="buttonCallbacks" />
  </td>
</template>

<script>
import PlaylistButtons from './ActionTextButtons.vue';
import ActionButtons from "./ActionButtons.vue";

export default {
  name: 'PlaylistItemContainer',
  components: {
    ActionButtons,
    PlaylistButtons
  },
  props: {
    small: {
      type: Boolean,
      required: true,
      default: false
    },
    playlist: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      localFavorite: this.playlist.favorite,
      localImage: this.playlist.image? this.playlist.image.replace('public/', '') : require('@/assets/cms/playlist.png')
    }
  },
  watch: {
    'playlist.favorite': function(newVal) {
      this.localFavorite = newVal;
    },
    localFavorite: function(newVal) {
      this.$emit('favorite', { favorite: newVal>0, id: this.playlist._id });
    }
  },
  computed: {
    buttonCallbacks() {
      return {
        copy: this.copyPlaylist,
        edit: this.editPlaylist,
        delete: this.deletePlaylist
      }
    }
  },
  methods: {
    copyPlaylist() {
      this.$emit('copy');
    },
    editPlaylist() {
      this.$router.push(`/playlist/edit/${this.playlist._id}`);
    },
    deletePlaylist() {
      this.$emit('delete');
    },
    navigateToEdit() {
      this.$emit('edit');
    }
  }
}
</script>

<style scoped>
img {
  width: 60px;
  height: 34px;
}
button {
  height: 27px;
  background-color: #7a66fb;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.list-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 5px 10px;
  background-color: #e6e6e7;
  border-radius: 5px;
  color: black; /* Set the default text color to black */
}

.list-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.favorite-checkbox {
  display: none;
}

.list-item a {
  flex-grow: 1;
  cursor: pointer;
}

.favorite-checkbox + label {
  width: 17px;
  height: 16px;
  background-image: url('@/assets/cms/favorite_unchecked.svg');
  background-size: cover;
}

.favorite-checkbox:checked + label {
  background-image: url('@/assets/cms/favorite.svg');
}

.favorite-checkbox:checked + label.white,
.favorite-checkbox + label.white {
  filter: brightness(0) saturate(100%) invert(1);
}
</style>
<template>
  <div>
    <h5>시간 반복 설정</h5>
    <div class="date-picker-container">
      <VueDatePicker
          v-model="localStartDate"
          :format="format"
          :flow="flow"
          :max-date="localEndDate"
          :disabled="!editable"
          @update:model-value="handleStartDate"
          @cleared = "handleStartDate" />
      <span>-</span>
      <VueDatePicker
          v-model="localEndDate"
          :format="format"
          :flow="flow"
          :min-date="localStartDate"
          :disabled="!editable"
          @update:model-value="handleEndDate"
          @cleared = "handleEndDate" />
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    VueDatePicker
  },
  props: {
    startDate: {
      type: Date,
      required: true
    },
    endDate: {
      type: Date,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      localStartDate: '',
      localEndDate: '',
      format: 'yy/MM/dd HH:mm',
      flow: ['calendar', 'time']
    }
  },
  watch: {
    startDate(newVal) {
      this.localStartDate = newVal;
    },
    endDate(newVal) {
      this.localEndDate = newVal;
    }
  },
  methods: {
    handleStartDate(modelData){
      this.localStartDate = modelData;
      this.$emit('periodChanged', {startDate: this.localStartDate, endDate: this.localEndDate});
    },
    handleEndDate(modelData){
      this.localEndDate = modelData;
      this.$emit('periodChanged', {startDate: this.localStartDate, endDate: this.localEndDate});
    },
  }
}
</script>

<style scoped>

h5 {
  margin: 0 0 5px 0;
}

span {
  display: inline-block;
  line-height: 38px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

button {
  white-space: nowrap;
  background-color: #7a66fb; /* Set button background color */
  padding: 0 10px; /* Add padding to the button */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

.date-picker-container {
  display: inline-grid;
  margin-right: 10px;
  grid-template-columns: 180px 10px 180px;
  gap: 5px;
}

</style>

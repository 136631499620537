<template>
  <button @click="downloadLog">Download Log</button>
  <div style="width: 600px; display: inline-flex;margin: 20px 0;justify-content: flex-end;align-items: center;">
    <label>n초룰 : </label>
    <input type="number" v-model="contentReceiveDuration" :readonly="!editingDuration">
    <button v-if="!editingDuration" @click="editDuration">수정하기</button>
    <button v-else @click="saveDuration">적용하기</button>
  </div>
  <div class="terminal">
    <ul v-if="logLines.length > 0">
      <li v-for="line in logLines" :key="line">{{ line }}</li>
    </ul>
    <p v-else>No log data available yet.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      logLines: [],
      previousLog: '',
      maxLines: 1000,
      contentReceiveDuration: 0,
      editingDuration: false,
    };
  },
  mounted() {
    this.loadDuration();
    this.readLog();
    setInterval(this.readLog, 500);
  },
  methods: {
    async loadDuration() {
      try {
        const response = await axios.get('/cms/contentReceiveDuration/load');
        this.contentReceiveDuration = response.data;
      } catch (error) {
        console.error(`Error loading duration: ${error}`);
      }
    },
    editDuration() {
      this.editingDuration = true;
    },
    async saveDuration() {
      try {
        await axios.put('/cms/contentReceiveDuration/update', {contentReceiveDuration: this.contentReceiveDuration});
        this.editingDuration = false;
      } catch (error) {
        console.error(`Error saving duration: ${error}`);
      }
    },
    async readLog() {
      try {
        const response = await axios.get('/logs/api.log');
        const logData = response.data;
        if (logData !== this.previousLog) {
          const lines = logData.split('\n');
          this.logLines = lines.slice(Math.max(lines.length - this.maxLines, 0)).reverse();
          this.previousLog = logData;
        }
      } catch (error) {
        console.error(`Error reading log file: ${error}`);
      }
    },
    async downloadLog() {
      try {
        const response = await axios.get('/logs/api.log', {responseType: 'blob'});
        const file = new Blob([response.data], {type: 'text/plain'});
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = 'log.txt';
        document.body.appendChild(element);
        element.click();
      } catch (error) {
        console.error(`Error downloading log file: ${error}`);
      }
    }
  }
};
</script>


<style scoped>
button {
  height: 35px;
  background-color: #7a66fb;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px;
}

input {
  width: 100px;
  height: 35px;
  margin: 0 20px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

input:read-only {
  background-color: #f5f5f8;
}

.terminal {
  background-color: #000;
  color: #0F0;
  font-family: 'Courier New', monospace;
  padding: 20px;
  white-space: pre-wrap;
  overflow: auto;
  height: 85vh;
}

.terminal::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
  height: 8px; /* Adjust height as desired for horizontal scrollbars */
}

.terminal::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.terminal::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.terminal::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Thumb hover color */
}
</style>
<template>
  <div class="popup-overlay" @click.self="close">
    <div class="popup-content">
      <h2 :title="name">{{ this.name }}</h2>
      <h3>재생 시간</h3>
      <div style="display: flex; justify-content: space-evenly; align-items: center;">
      <select v-model="startHour" id="start_hour" :disabled="lockStart">
        <option v-for="hour in 25" v-bind:key="hour-1">{{ hour-1 }}</option>
      </select>
      <p>:</p>
      <select v-model="startMinute" id="start_minute" :disabled="lockStart">
        <option v-for="minute in 60" v-bind:key="minute-1">{{ minute-1 }}</option>
      </select>
      <p>~</p>
      <select v-model="endHour" id="end_hour">
        <option v-for="hour in (25-Number(startHour))" v-bind:key="hour+Number(startHour)-1">{{ hour+Number(startHour)-1 }}</option>
      </select>
      <p>:</p>
      <select v-model="endMinute" id="end_minute">
        <option v-for="minute in 60" v-bind:key="minute-1">{{ minute-1 }}</option>
      </select>
      </div>
      <button id="save-button" @click="save" :disabled="isAnySelectUnselected">저장하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPopup',
  props: {
    name: {
      type: String,
      required: true,
    },
    start: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      required: true,
    },
    timeRemaining: {
      type: Number,
      required: true,
    },
    lockStart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
    }
  },
  created() {
    this.startHour = Math.floor(this.start / 3600);
    this.startMinute = Math.floor((this.start % 3600) / 60);
    this.endHour = Math.floor(this.end / 3600);
    this.endMinute = Math.floor((this.end % 3600) / 60);
    this.localTimeRemaining = this.timeRemaining + (this.end - this.start);
  },
  computed: {
    isAnySelectUnselected() {
      // Check if any of the select fields are not selected
      return this.startHour === null || this.startMinute === null || this.endHour === null || this.endMinute === null || this.startHour === '' || this.startMinute === '' || this.endHour === '' || this.endMinute === '' || (this.startHour*60+this.startMinute) >= (this.endHour*60+this.endMinute);
    },
  },
  watch: {
    startHour() {
      this.startHour = Number(this.startHour);
    },
    startMinute() {
      this.startMinute = Number(this.startMinute);
    },
    endHour() {
      if (this.startHour == this.endHour && this.startMinute == this.endMinute) {
        this.endHour = (this.startHour + 1).toString();
      }
      if (this.startHour == this.endHour && this.startMinute > Number(this.endMinute)) {
        this.endMinute = this.startMinute.toString();
      }
      this.limitEnd();
    },
    endMinute() {
      if (this.startHour == this.endHour && this.startMinute > this.endMinute) {
        this.startMinute = this.endMinute;
      }
      if (this.startHour == this.endHour && this.startMinute == this.endMinute) {
        this.endMinute = (this.startMinute + 1).toString();
      }
      this.limitEnd();
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save', {
        start: this.startHour * 3600 + this.startMinute * 60,
        end: this.endHour * 3600 + this.endMinute * 60,
      });
    },
    limitEnd(){
      if (this.startHour * 3600 + this.startMinute * 60 + this.localTimeRemaining < Number(this.endHour) * 3600 + Number(this.endMinute) * 60) {
        this.endHour = Math.floor((this.startHour * 3600 + this.startMinute * 60 + this.localTimeRemaining) / 3600).toString();
        this.endMinute = Math.floor(((this.startHour * 3600 + this.startMinute * 60 + this.localTimeRemaining) % 3600) / 60).toString();
      }
    },
  },
}
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h3 {
  margin-top: 0;
  text-align: center;
}

p {
  margin: 0;
}

button {
  width: 90px;
  height: 35px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: 1px solid #9b9b9b; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
  margin: 20px auto 0;
}

.popup-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 376px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 38px 48px;
}


select {
  display: inline;
  min-height: 35px;
  max-height: 35px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}
</style>
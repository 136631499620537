<template>
  <div class="checkbox-container" style="margin-top: 10px; margin-bottom: 10px">
    <h3 style="grid-column: 1; grid-row: 1;">필터</h3>
    <div class="checkbox-item" style="grid-column: 2; grid-row: 1;">
      <input type="checkbox" id="filter-1" name="filter" v-model="localFilters.favorite" @change="emitFilterChange">
      <label for="filter-1"><img src="@/assets/cms/favorite.svg"/></label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterCheckboxes',
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFilters: {
        favorite: false,
      },
    };
  },
  watch: {
    filters: {
      handler() {
        this.localFilters = {
          favorite: this.filters.favorite,
        };
      },
      immediate: true,
    },
  },
  methods: {
    emitFilterChange() {
      this.$emit('onFilterChange', this.localFilters, []);
    }
  }
}
</script>

<style scoped>

h3 {
  font-size: 16px; /* Set the font size to 16px */
  font-weight: 500; /* Set the font weight to 700 */
  margin-top: 0; /* Remove the default margin */
  margin-bottom: 0;
}

.checkbox-container {
  display: grid; /* Change to grid layout */
  grid-template-rows: minmax(27px, auto); /* Divide the top-bar into two columns */
  grid-template-columns: 50px repeat(4, 117px); /* Divide the top-bar into two columns */
  align-items: center; /* Center items vertically */
}

.checkbox-container input[type="checkbox"] {
  border: 2px solid #bcb2fd; /* Set the border color to #7a66fc */
  border-radius: 3px; /* Set the border radius to 3px */
  appearance: none; /* Remove default checkbox appearance */
  -webkit-appearance: none; /* Remove default checkbox appearance for Webkit browsers */
  -moz-appearance: none; /* Remove default checkbox appearance for Mozilla browsers */
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative; /* Needed for pseudo-element positioning */
}

.checkbox-container input[type="checkbox"]:checked {
  border: 2px solid #7a66fc; /* Set the border color to #7a66fc */
  background-color: #7a66fc; /* Set the background color to #7a66fc when checked */
}

.checkbox-container label {
  padding-left: 5px; /* Add a margin between the checkbox and label */
  font-size: 12px; /* Set the font size to 12px */
  font-weight: 500; /* Set the font weight to 500 */
}

.checkbox-item {
  display: flex; /* Allow elements to sit side-by-side */
  align-items: center; /* Center items vertically */
}
</style>
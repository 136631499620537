<template>
  <div class="login">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div>
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required>
      </div>
      <div class="password-container">
        <label for="password">Password:</label>
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required>
        <button type="button" @mousedown="showPassword = true" @mouseup="showPassword = false" @mouseleave="showPassword = false">Show</button>
      </div>
      <button type="submit">Login</button>
    </form>
    <p class="error" v-if="loginError">{{ loginError }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      loginError: '',
      showPassword: false
    }
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/login', {
          username: this.username,
          password: this.password
        });

        if (response.data.success) {
          this.$router.push('/timeline');
        } else {
          this.loginError = 'Invalid username or password';
        }
      } catch (error) {
        console.error(error);
        this.loginError = 'An error occurred while trying to log in';
      }
    }
  }
}
</script>

<style scoped>
.login {
  width: 300px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 10px;
}

input {
  height: 35px;
  padding: 0 10px;
  margin: 5px 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

button {
  height: 35px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

button:hover {
  background-color: #6b5ce7; /* Change button background color on hover */
}

button:active {
  background-color: #5c4ed3; /* Change button background color on click */
}

.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  margin-right: 10px;
}

button {
  padding: 10px;
}
</style>
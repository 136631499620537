<template>
  <div>
    <h5>요일 반복</h5>
    <div class="checkbox-container">
      <label class="day-checkbox" v-for="(day, index) in days" :key="index">
        <input type="checkbox" v-model="selectedDays[index]" :disabled="!editable" @change="dayChanged()">
        <span class="checkmark">{{ day }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    daysOfWeek: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      days: ['월', '화', '수', '목', '금', '토', '일'],
      selectedDays: new Array(7).fill(false)
    }
  },
  watch: {
    daysOfWeek(newVal) {
      this.selectedDays = new Array(7).fill(false);
      newVal.forEach(day => {
        this.selectedDays[day] = true;
      });
    }
  },
  methods: {
    dayChanged() {
      let days =  this.selectedDays.map((day, index) => day ? index : -1).filter(day => day !== -1);
      this.$emit('daysChanged', days);
    },
  }
}
</script>

<style scoped>
h5 {
  margin: 5px 0 3px 0;
}

.day-checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.day-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #ccccce;
  border-radius: 50%;
  line-height: 22px;
  text-align: center;
  color: black;
}

.day-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.day-checkbox input:checked ~ .checkmark {
  background-color: #7a66fc;
  color: white;
}
</style>
<template>
  <div class="block">
    <h2>태그 수정하기</h2>
    <span style="border-bottom: 2px solid #9b9b9b; margin-top: 10px; margin-bottom: 10px; display: block;"></span>
    <table>
      <thead>
      <tr>
        <th class="col-no">
          <div class="col-container">
            No.
          </div>
        </th>
        <th class="col-name">
          <div class="col-container">
            태그명
          </div>
        </th>
        <th class="col-count">
          <div class="col-container">
            입력 콘텐츠 수
          </div>
        </th>
        <th class="col-edit">
          <div class="col-container" style=" border-right: none;">
            수정하기
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(tag, index) in tags" :key="tag._id">
        <td>{{ index }}.</td>
        <td>
          <input v-if="editingTagIndex === index" type="text" v-model="tag.name"
                 @keyup.enter="saveTag(index)"
                 @keyup.esc="editingTagIndex = -1"
                 @blur="saveTag(index)"
                 ref="tagNameInput"
                 autofocus>
          <span v-else>{{ tag.name }}</span>
        </td>
        <td>{{ tag.count }}</td>
        <td>
          <button :disabled="editingTagIndex === index || index === 0" @click="editTag(index)">수정하기</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  props: {},
  data() {
    return {
      tags: {},
      editingTagIndex: -1,
    };
  },
  created() {
    axios.get('/cms/tag/list')
        .then((response) => {
          this.tags = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
  },
  computed: {},
  methods: {
    editTag(index) {
      this.editingTagIndex = index;
      this.$nextTick(() => {
        this.$refs.tagNameInput.focus();
      });
    },
    saveTag(index) {
      const tag = this.tags[index];
      axios.post('/cms/tag/update', tag)
          .then(() => {
            this.editingTagIndex = -1;
          })
          .catch((error) => {
            console.error(error);
          });
      this.editingTagIndex = -1;
    },
  },
};
</script>

<style scoped>

button {
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

button:disabled {
  background-color: #ccc; /* Set disabled button background color */
  cursor: not-allowed; /* Indicate non-clickable behavior */
}

table {
  width: 100%;
  border-collapse: collapse; /* Collapse the borders of the table cells */
  margin: 20px 0; /* Add some margin above and below the table */
  border: 2px solid #9b9b9b; /* Add a light gray border around the table */
}

thead {
  background-color: #f5f5f8; /* Light gray background color */
  color: #9a9a9a;
  font-weight: 700;
  border-bottom: 1px solid #9b9b9b;
}

tbody {
  padding: 10px; /* Add some padding inside the table body */
  font-weight: 700;
}

td {
  padding: 10px 20px; /* Add some padding inside the table cells */
  border-right: 1px solid #ccccce;
  border-bottom: 1px solid #ccccce;
}

input {
  width: 95%;
  padding: 5px; /* Add some padding inside the input field */
  border: 1px solid #ccc; /* Add a light gray border around the input field */
  border-radius: 3px; /* Set the corner radius to 3px for rounded corners */
  font-size: 14px; /* Set the input text size */
  font-weight: 700; /* Set the input text weight */
}

.col-container {
  padding: 0px 20px; /* Add some padding inside the table cells */
  margin: 10px 0;
  border-right: 1px solid #9b9b9b; /* Add a light gray border at the right of each cell */
}

.col-no {
  width: 63px;
}

.col-name {
  width: 357px;
}

.col-count {
  width: 194px;
}

.col-edit {
  width: auto;
  text-align: start;
}

.block {
  background-color: #fff; /* White background color */
  border-radius: 10px; /* Set the corner radius to 10px for rounded corners */
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1); /* Add a slight drop shadow */
  padding: 10px 20px; /* Add some padding inside the block */
}
</style>
<template>
  <div class="container">
    <TimelineContainer class="left" :activeTimeline="activeTimeline" />
      <PlaylistContainer
          :items-per-page="6"
          :small="true"
          :draggable="true"
          :group="'timeline-idle'"
          :usePageParam="false"
      />
      <ContentContainer
          itemContainer="SmallContentItemContainer"
          title="CONTENT"
          path="content"
          :itemsPerPage="6"
          :onlyPriority2="true"
          :group="'timeline-vip'"
          :usePageParam="false"
      />
  </div>
</template>

<script>
import ContentContainer from "@/components/cms/ContentContainer.vue";
import PlaylistContainer from "@/components/cms/PlaylistContainer.vue";
import TimelineContainer from "@/components/cms/TimelineContainer.vue";

export default {
  components: {
    TimelineContainer,
    PlaylistContainer,
    ContentContainer,
  },
  props: {
    activeTimeline: Object,
  },
};
</script>

<style scoped>

h2 {
  font-size: 20px; /* Set the font size to 20px */
  font-weight: 600;
  margin-top: 0; /* Remove the default margin */
  margin-bottom: 5px; /* Add a 10px margin at the bottom */
}

/* Style the container */
.container {
  display: grid; /* Change to grid layout */
  grid-template-columns: 6fr 4fr; /* Divide the top-bar into two columns */
  grid-template-rows: 1fr 1fr;
  gap: 10px; /* Add a 10px gap between the columns */
  height: 80vh;
}

/* Style the left div */
.left {
  grid-row-start: 1;
  grid-row-end: 3;
}


.object-list {
  display: grid;
  row-gap: 9px; /* Add a 9px gap between the rows */
}
</style>

<template>
  <div class="checkbox-container" style="margin-top: 10px; margin-bottom: 10px">
    <h3 style="grid-column: 1; grid-row: 1;">필터</h3>
    <div class="checkbox-item" style="grid-column: 2; grid-row: 1;">
      <input type="checkbox" id="filter-1" name="filter" v-model="localFilters.favorite" @change="emitFilterChange">
      <label for="filter-1"><img src="@/assets/cms/favorite.svg"/></label>
    </div>
    <div class="checkbox-item" style="grid-column: 3; grid-row: 1;" v-if="!onlyPriority4 && !onlyPriority2 && path !== 'epcontent'">
      <input type="checkbox" id="filter-2" name="filter" v-model="localFilters.priority_2" @change="emitFilterChange">
      <label for="filter-2">VIP</label>
    </div>
    <div class="checkbox-item" style="grid-column: 4; grid-row: 1;" v-if="!onlyPriority4 && !onlyPriority2 && path !== 'epcontent'">
      <input type="checkbox" id="filter-3" name="filter" v-model="localFilters.priority_4" @change="emitFilterChange">
      <label for="filter-3">고정</label>
    </div>

    <h3 style="grid-column: 1; grid-row: 2;">태그</h3>
    <div class="checkbox-item" style="grid-column: 2; grid-row: 2;">
      <input type="checkbox" id="tag-1" name="tag" v-model="localTags[0].value" @change="emitFilterChange">
      <label for="tag-1">{{ tagNames[1] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 3; grid-row: 2;">
      <input type="checkbox" id="tag-2" name="tag" v-model="localTags[1].value" @change="emitFilterChange">
      <label for="tag-2">{{ tagNames[2] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 4; grid-row: 2;">
      <input type="checkbox" id="tag-3" name="tag" v-model="localTags[2].value" @change="emitFilterChange">
      <label for="tag-3">{{ tagNames[3] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 5; grid-row: 2;">
      <input type="checkbox" id="tag-4" name="tag" v-model="localTags[3].value" @change="emitFilterChange">
      <label for="tag-4">{{ tagNames[4] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 2; grid-row: 3;">
      <input type="checkbox" id="tag-5" name="tag" v-model="localTags[4].value" @change="emitFilterChange">
      <label for="tag-5">{{ tagNames[5] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 3; grid-row: 3;">
      <input type="checkbox" id="tag-6" name="tag" v-model="localTags[5].value" @change="emitFilterChange">
      <label for="tag-6">{{ tagNames[6] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 4; grid-row: 3;">
      <input type="checkbox" id="tag-7" name="tag" v-model="localTags[6].value" @change="emitFilterChange">
      <label for="tag-7">{{ tagNames[7] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 5; grid-row: 3;">
      <input type="checkbox" id="tag-8" name="tag" v-model="localTags[7].value" @change="emitFilterChange">
      <label for="tag-8">{{ tagNames[8] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 2; grid-row: 4;">
      <input type="checkbox" id="tag-9" name="tag" v-model="localTags[8].value" @change="emitFilterChange">
      <label for="tag-9">{{ tagNames[9] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 3; grid-row: 4;">
      <input type="checkbox" id="tag-10" name="tag" v-model="localTags[9].value" @change="emitFilterChange">
      <label for="tag-10">{{ tagNames[10] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 4; grid-row: 4;">
      <input type="checkbox" id="tag-11" name="tag" v-model="localTags[10].value" @change="emitFilterChange">
      <label for="tag-11">{{ tagNames[11] }}</label>
    </div>
    <div class="checkbox-item" style="grid-column: 5; grid-row: 4;">
      <input type="checkbox" id="tag-12" name="tag" v-model="localTags[11].value" @change="emitFilterChange">
      <label for="tag-12">{{ tagNames[12] }}</label>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'FilterCheckboxes',
  props: {
    onlyPriority2: {
      type: Boolean,
      default: false,
    },
    onlyPriority4: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      tagNames: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: '',
      },
      localFilters: {
        favorite: false,
        priority_2: this.onlyPriority2,
        priority_4: this.onlyPriority4,
      },
      localTags: [
        { name: '1', value: false },
        { name: '2', value: false },
        { name: '3', value: false },
        { name: '4', value: false },
        { name: '5', value: false },
        { name: '6', value: false },
        { name: '7', value: false },
        { name: '8', value: false },
        { name: '9', value: false },
        { name: '10', value: false },
        { name: '11', value: false },
        { name: '12', value: false },
      ]
    };
  },
  mounted() {
    axios.get('/cms/tag/list')
        .then((response) => {
          const tempTags = response.data;
          this.tagNames = tempTags.reduce((acc, tag) => {
            acc[tag.index] = tag.name;
            return acc;
          }, {});
        })
        .catch((error) => {
          console.error(error);
        })
  },
  watch: {
    filters: {
      handler() {
        this.localFilters = {
          favorite: this.filters.favorite,
          priority_2: this.filters.priority_2,
          priority_4: this.filters.priority_4,
        };
      },
      immediate: true,
    },
    tags: {
      handler() {
        this.localTags = this.tags.map((tag, index) => {
          return {
            name: index + 1,
            value: tag.value,
          };
        });
      },
      immediate: true,
    }
  },
  methods: {
    emitFilterChange() {
      this.$emit('onFilterChange', this.localFilters, this.localTags);
    }
  }
}
</script>

<style scoped>

h3 {
  font-size: 16px; /* Set the font size to 16px */
  font-weight: 500; /* Set the font weight to 700 */
  margin-top: 0; /* Remove the default margin */
  margin-bottom: 0;
}

.checkbox-container {
  display: grid; /* Change to grid layout */
  grid-template-rows: 27px repeat(3, 1fr); /* Divide the top-bar into two columns */
  grid-template-columns: 50px repeat(4, 140px); /* Divide the top-bar into two columns */
  align-items: center; /* Center items vertically */
}

.checkbox-container input[type="checkbox"] {
  border: 2px solid #bcb2fd; /* Set the border color to #7a66fc */
  border-radius: 3px; /* Set the border radius to 3px */
  appearance: none; /* Remove default checkbox appearance */
  -webkit-appearance: none; /* Remove default checkbox appearance for Webkit browsers */
  -moz-appearance: none; /* Remove default checkbox appearance for Mozilla browsers */
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative; /* Needed for pseudo-element positioning */
}

.checkbox-container input[type="checkbox"]:checked {
  border: 2px solid #7a66fc; /* Set the border color to #7a66fc */
  background-color: #7a66fc; /* Set the background color to #7a66fc when checked */
}

.checkbox-container label {
  padding-left: 5px; /* Add a margin between the checkbox and label */
  font-size: 16px; /* Set the font size to 12px */
  font-weight: 500; /* Set the font weight to 500 */
}

.checkbox-item {
  display: flex; /* Allow elements to sit side-by-side */
  align-items: center; /* Center items vertically */
}
</style>
<template>
  <div class="list-item-container">
    <span class="index">{{ index + 1 }}</span>
    <div class="list-item left">
      <span class="message" :title="content.message">{{ content.message }}</span>
    </div>
    <div class="list-item mid">
      <span class="priority">{{ priorityText }}</span>
      <div class="divider"/>
      <span class="repeat">{{ content.repeat }}회</span>
      <div class="divider"/>
      <span class="animation">{{ animationText }}</span>
      <div class="divider"/>
      <span class="font" :title="fontText">{{ fontText }}</span>
      <div class="divider"/>
      <input type="checkbox" :id="`favorite-${content._id}`" class="favorite-checkbox" v-model="localFavorite"/>
      <label :for="`favorite-${content._id}`"></label>
    </div>
    <ActionButtons :enabled="(content.priority !== 6) && !(content.priority == 4 && content.tags.includes(1) && content.weather.value == 100)"
        :callbacks="buttonCallbacks" />
  </div>
</template>

<script>
import ActionButtons from './ActionTextButtons.vue';
export default {
  name: 'ContentItemContainer',
  components: {
    ActionButtons
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      localFavorite: this.content.favorite || 0,
      priorityMap: {
        0: "",
        1: "",
        2: "VIP",
        3: "EPSD",
        4: "고정",
        5: "Guest",
        6: "미디어아트"
      },
      animationMap: {
        0: '페이드인',
        1: '페이드 아웃',
        2: '날아오기',
        3: '디졸브',
        4: '닦아내기',
        5: '트레이싱',
        6: '얼굴',
        7: '반전',
      },
      fontMap: {
        0: 'Arial',
        1: 'Calibri',
        2: 'Calibri Light',
        3: 'Elice DX Neolli Light',
        4: 'Galmuri14 Regular',
        5: 'GalmuriMono11 Regular',
        6: 'Georgia',
        7: 'Gulim',
        8: 'GulimChe',
        9: 'Mongolian Baiti',
        10: 'Noto Sans',
        11: 'Noto Sans KR',
        12: 'Noto Sans TC',
        13: 'Noto Sans JP',
        14: 'Noto Kufi Arabic',
        15: 'Open Sans',
        16: 'SansSerif',
        17: 'SUITE Light',
        18: 'Technic',
        19: 'Verdana',
      },
    }
  },
  watch: {
    'content.favorite': function(newVal) {
      this.localFavorite = newVal;
    },
    localFavorite: function(newVal) {
      this.$emit('favorite', { favorite: newVal>0, id: this.content._id });
    }
  },
  computed: {
    priorityText() {
      return this.priorityMap[this.content.priority];
    },
    animationText() {
      if (this.content.animation === undefined || this.content.animation === null) {
        return '-';
      }
      return this.animationMap[this.content.animation];
    },
    fontText() {
      if (this.content.font  === undefined || this.content.font === null) {
        return '-';
      }
      return this.fontMap[this.content.font];
    },
    buttonCallbacks() {
      return {
        copy: this.copyContent,
        edit: this.editContent,
        delete: this.deleteContent
      }
    }
  },
  methods: {
    copyContent() {
      this.$emit('copy');
    },
    editContent() {
      this.$emit('edit');
    },
    deleteContent() {
      this.$emit('delete');
    }
  }
}
</script>

<style scoped>

span {
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  border: 2px solid #c4c4c6;
  gap: 10px;
}

.left {
  flex-grow: 1;
}

.mid {
  flex-shrink: 0;
}

.list-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.index {
  width: 30px;
  max-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.favorite-checkbox {
  display: none; /* Hide the checkbox */
}

.list-item span {
  flex-grow: 1;
}

.favorite-checkbox + label {
  width: 17px;
  height: 16px;
  background-image: url('@/assets/cms/favorite_unchecked.svg');
  background-size: cover;
}

.favorite-checkbox:checked + label {
  background-image: url('@/assets/cms/favorite.svg');
}

.divider {
  min-width: 1px;
  max-width: 1px;
  height: 20px;
  border-left: 1px solid #9b9b9b;
}

.priority {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.animation {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.font {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.repeat {
  width: 40px;
  max-width: 40px;
  text-align: center;
}
</style>
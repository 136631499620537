<style >
:root {
  --message: '';
  --font-family: Noto Sans KR,sans-serif;
  --font-size: 10vh;
  --line-height: 10;
  --letter-spacing: 100;
  --font-color: #fff;
  --background-color: #000;
  --section-1-sec: 16s;
  --section-2-sec: 4s;
  --section-3-sec: 8s;
  --place-items: center start;
  --canvas-text-align: left;
  --canvas-white-space: normal;

  /* text 왼쪽 여백 */
  --text-left-space:40px;
  --text-scale-x:1.2;
  --text-scale-y:1;
  --transform-origin:left;

  /* canvas 설정 */
  --canvas-width:154px;

  /* svg 관련 설정 */
  --svg-margin-top:-73px;
  --svg-margin-left:0px;
  --svg-width:0px;

  /* face img 설정 */
  --face-img-length:200px;
  --face-text-margin-top:30px;
}
</style>

<template>
  <div ref="canvas" :class="['canvas', canvasClass]"  :style="canvasStyle">
    <div ref="contentBox" class="contentBox" :style="contentBoxStyle">

      <!--   section 1   -->
      <div v-if="currentText === 1" class="textWrapper" >
        <!-- animation 5 : 트레이싱 -->
        <div v-if="animation === 5" class="trace">
          <div ref="traceText" :class="['text', 'trace-text', animationClass1]" v-html="showMessageOneLine1"></div>
          <svg class="line-drawing" :viewBox="svgViewBox" :width="svgWidth+'px'" :height="svgHeight+'px'">
            <path class="line vertical1" :d="svgLineVertical1" />
            <path class="line vertical2" :d="svgLineVertical2" />
            <path class="line horizontal1" :d="svgLineTop" />
            <path class="line horizontal2" :d="svgLineBottom" />
            <path class="line vertical-reverse1" :d="svgLineReverse1" />
            <path class="line vertical-reverse3" :d="svgLineReverse2" />
            <path class="line vertical-reverse2" :d="svgLineReverse3" />
          </svg>
        </div>
        <!-- animation 6 : 얼굴 , animation 7 : 반전 -->
        <div v-else-if="animation === 6 || animation === 7" class="face" :style="faceTextWrap" >
          <div ref="faceText" class="face-text1" v-html="replaceToBr1" ></div>
        </div>
        <!-- animation 2 : 날이오기 -->
        <div v-else-if="animation === 2" v-html="showMessageOneLine1" :class="['text', animationClass1]"></div>
        <!-- animation 그 외 -->
        <div v-else v-html="replaceToBr1" :class="['text', animationClass1]"></div>
      </div>

      <!-- animation 6 : 얼굴 -->
      <div v-if="currentText === 1 && (animation === 6 || animation === 7)" :style="faceImgWrap" >
        <img :src="require('@/assets/cms/animation-face.gif')" :class="animationSubClass1"/>
      </div>
      <div v-if="currentText === 1 && (animation === 6 || animation === 7)" :style="faceImgBox" :class="animationSubClass2"></div>
      <!--  // section 1   -->

      <!--   section 2   -->
      <div v-if="currentText === 2" class="textWrapper">
        <div v-if="animation === 5" class="trace">
          <div ref="traceText" :class="['text', 'trace-text', animationClass2]" v-html="showMessageOneLine2"></div>
          <svg class="line-drawing" :viewBox="svgViewBox" :width="svgWidth+'px'" :height="svgHeight+'px'">
            <path class="line vertical1" :d="svgLineVertical1" />
            <path class="line vertical2" :d="svgLineVertical2" />
            <path class="line horizontal1" :d="svgLineTop" />
            <path class="line horizontal2" :d="svgLineBottom" />
            <path class="line vertical-reverse1" :d="svgLineReverse1" />
            <path class="line vertical-reverse3" :d="svgLineReverse2" />
            <path class="line vertical-reverse2" :d="svgLineReverse3" />
          </svg>
        </div>
        <div v-else-if="animation === 6" class="face text">
          <div class="face-text2" v-html="replaceToBr2"></div>
        </div>
        <div v-else v-html="replaceToBr2" :class="['text', animationClass2]"></div>
      </div>
      <!--  // section 2   -->

      <!--   section 3   -->
      <div v-if="currentText === 3" class="textWrapper">
        <div v-if="animation === 5" class="trace">
          <div ref="traceText" :class="['text', 'trace-text', animationClass3]" v-html="showMessageOneLine3"></div>
          <svg class="line-drawing" :viewBox="svgViewBox" :width="svgWidth+'px'" :height="svgHeight+'px'">
            <path class="line vertical1" :d="svgLineVertical1" />
            <path class="line vertical2" :d="svgLineVertical2" />
            <path class="line horizontal1" :d="svgLineTop" />
            <path class="line horizontal2" :d="svgLineBottom" />
            <path class="line vertical-reverse1" :d="svgLineReverse1" />
            <path class="line vertical-reverse3" :d="svgLineReverse2" />
            <path class="line vertical-reverse2" :d="svgLineReverse3" />
          </svg>
        </div>
        <div v-else-if="animation === 6" class="face">
          <div class="face-text3" v-html="replaceToBr3"></div>
        </div>
        <div v-else v-html="replaceToBr3" :class="['text', animationClass3]"></div>
      </div>
      <!--  // section 3   -->

    </div>
  </div>
  <div class="btn-wrap">
    <button class="play-btn" v-show="this.showMediaBtns" @click="resume" :disabled="!isPaused" title="재생하기"></button>
    <button class="stop-btn" v-show="this.showMediaBtns" @click="pause" :disabled="isPaused" title="일시정지"></button>
    <button class="refresh-btn" v-show="this.showMediaBtns" @click="restart" title="새로고침"></button>
    <button ref="previewBtn" :disabled="isDisabledPreBtn" class="preview-btn" @click="preview">미리보기</button>
  </div>
</template>

<script>
//const ratioWidth = 154;   //미디어월 가로 비율
//const ratioHeight = 38;   //미디어월 세로 비율

export default {
  data() {
    return {
      //캔버스 크기
      canvasWidth: 154,
      canvasHeight: 38,
      canvasMarginTop: 0,
      canvasMarginLeft: 0,

      //svg 설정
      svgViewBox: '0 0 681 169',
      svgWidth: 681,
      svgHeight: 169,
      svgLineVertical1: 'M50 0 V 50',
      svgLineVertical2: 'M130 0 V 50',
      svgLineTop: 'M850 50 H 0',
      svgLineBottom: 'M0 150 H 850',
      svgLineReverse1: 'M10 240 V 150',
      svgLineReverse2: 'M80 240 V 150',
      svgLineReverse3: 'M180 240 V 150',

      //face 설정
      faceTextMarginTop: 30,
      faceTextOffsetHeight : 0,

      //미리보기 버튼
      isDisabledPreBtn: false,
      showMediaBtns: false,

      //섹션 개수
      prevSectionCount: 1,
      nextSectionCount: 1,

      //입력값 관련
      isMultiRows: false,
      textLeftSpace: 30,

      //애니메이션 효과 관련
      currentText: 0,
      repeatCount: 0,
      maxRepeats: 2,
      isPaused: false,
      timers: [],
      durations: [13000, 3000, 3000], // Durations for each text
      remainingTime: 13000, // Initial time for the first text
      pauseStartTime: null, // When the pause was started
      animationEffects: ['dissolve', 'fadeIn', 'fadeOut', 'wipe', 'trace', 'face', 'invert', 'flying'], // Animation effects
      animationEffectIndex: 0, // Current animation effect index
      numberOfSections: 3, // 기본 섹션 개수
      animationClass1: 'fadeIn1', // Class for animation of section 1
      animationClass2: 'fadeIn2', // Class for animation of section 2
      animationClass3: 'fadeIn3', // Class for animation of section 3
      canvasClass: '',
      animationSubClass1: 'face-img1',
      animationSubClass2: 'face-img1-box'
    };
  },
  props: {
    ratioWidth: [String, Number],
    ratioHeight: [String, Number],
    duration: [String, Number],
    repeat: [String, Number],
    message: [String, Number],
    language: [String, Number],
    variable: [String, Number],
    font: [String, Number],
    font_size: [String, Number],
    line_height: [String, Number],
    letter_spacing: [String, Number],
    font_color: [String, Number],
    background_color: [String, Number],
    animation: [String, Number],
    animation_direction: [String, Number],
    sections: [String, Number],
    image_name: [String, Number],
    fonts: Object,
    font_colors: Object,
    animations: Object,
    animation_directions: Object,
  },
  mounted() {
    //캔버스 크기 조정
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    //애니메이션 효과 선택
    window.addEventListener('change', this.selectEffect);
    this.selectEffect();

    //섹션 속도 선택
    window.addEventListener('change', this.selectDuration);
    this.selectDuration();

    //반복 선택
    window.addEventListener('change', this.selectRepeat);
    this.selectRepeat();

    // CSS 변수에 durations 값 넣기
    window.addEventListener('change', this.setDurationsToCSS);
    this.setDurationsToCSS();

    // SVG width 값 설정
    document.documentElement.style.setProperty('--svg-width', `${this.svgWidth}px`);

  },
  unmounted() {
    //캔버스 크기 조정 해제
    window.removeEventListener('resize', this.handleResize);

    //애니메이션 효과 선택
    window.removeEventListener('change', this.selectEffect);

    //섹션 속도 선택
    window.removeEventListener('change', this.selectDuration);

    //반복 선택
    window.removeEventListener('change', this.selectRepeat);

    // CSS 변수에 durations 값 넣기
    window.removeEventListener('change', this.setDurationsToCSS);
  },
  watch: {
    ratioWidth(newVal) {
      if (newVal) {
        this.handleResize();
      }
    },
    ratioHeight(newVal) {
      if (newVal) {
        this.handleResize();
      }
    },
    message : function(){
      //섹션 개수 변경 시 미리보기 재시작
      if(this.isDisabledPreBtn){
        const msgs = this.message.split('%', 3);
        this.numberOfSections = msgs.length; // 섹션 개수 설정
        this.nextSectionCount = this.numberOfSections; // 섹션 개수 설정
        if (this.prevSectionCount != this.nextSectionCount) {
          //console.log("섹션 개수 변경으로 인한 미리보기 재시작");
          this.restart();
        }
        this.prevSectionCount = this.nextSectionCount;
      }
    },
  },
  computed: {
    replaceToBr1() {
      let msg1 = this.sliceMessage(1);
      if (msg1.indexOf('&') > -1) {
        this.setPlaceItems(true);
      } else {
        this.setPlaceItems(false);
      }
      msg1 = msg1 ? msg1.replaceAll("&", "<br/>") : '';
      return this.replaceSpaceToHtmlCode(msg1);
    },
    replaceToBr2() {
      let msg2 = this.sliceMessage(2);
      if (msg2.indexOf('&') > -1) {
        this.setPlaceItems(true);
      } else {
        this.setPlaceItems(false);
      }
      msg2 = msg2 ? msg2.replaceAll("&", "<br/>") : '';
      return this.replaceSpaceToHtmlCode(msg2);
    },
    replaceToBr3() {
      let msg3 = this.sliceMessage(3);
      if (msg3 === undefined) {
        //console.log("undefined");
        this.restart();
      }
      (msg3.indexOf('&') > -1) ? this.setPlaceItems(true) : this.setPlaceItems(false);
      msg3 = msg3 ? msg3.replaceAll("&", "<br/>") : '';
      return this.replaceSpaceToHtmlCode(msg3);
    },
    showMessageOneLine1() {
      let msg1 = this.sliceMessage(1);
      msg1 = this.replaceSpaceToHtmlCode(msg1);
      this.setPlaceItems(false);
      return msg1;
    },
    showMessageOneLine2() {
      let msg2 = this.sliceMessage(2);
      msg2 = this.replaceSpaceToHtmlCode(msg2);
      this.setPlaceItems(false);
      return msg2;
    },
    showMessageOneLine3() {
      let msg3 = this.sliceMessage(3);
      msg3 = this.replaceSpaceToHtmlCode(msg3);
      this.setPlaceItems(false);
      return msg3;
    },
    canvasStyle() {
      let backgroundColor = this.$props.background_color;

      let effectName = "";
      for (let i = 0; i < this.animations.length; i++) {
        if (this.animations[i].value === this.animation) {
          effectName = this.animations[i].code;
          break;
        }
      }
      if (effectName.indexOf('invert2') > -1) { //애니메이션 효과가 반전인 경우
        backgroundColor = '#ffffff';
      }

      return {
        width: this.canvasWidth + 'px',
        height: this.canvasHeight + 'px',
        margin: this.canvasMarginTop + 'px 0 ' + this.canvasMarginLeft + 'px 0',
        'background-color': backgroundColor
      }
    },
    faceTextWrap() {
      const faceTextLength = this.getFaceImgLength(this.canvasWidth, this.canvasHeight);

      let placeItems = '';
      let display = '';
      let paddingLeft = '30px';//document.documentElement.style.getPropertyValue('--text-left-space');
      const msgCurrent = this.sliceMessage(this.currentText);
      if (msgCurrent.indexOf('&') > -1) { //2줄
        placeItems = 'center';
        display = 'grid';
        paddingLeft = '0px';
      } else {
        placeItems = '';
        display = '';
        paddingLeft = document.documentElement.style.getPropertyValue('--text-left-space');
        //paddingLeft = '30px';
      }

      return {
        width: this.canvasWidth + 'px',
        height: faceTextLength + 'px',
        placeItems: placeItems,
        display: display,
        paddingLeft: paddingLeft
      }
    },
    faceImgWrap() {
      const faceImgLength = this.getFaceImgLength(this.canvasWidth, this.canvasHeight);
      return {
        width: faceImgLength + 'px',
        height: faceImgLength + 'px',
        marginTop: '-' + (faceImgLength+this.faceTextMarginTop) + 'px'
      }
    },
    faceImgBox() {
      const faceImgLength = this.getFaceImgLength(this.canvasWidth, this.canvasHeight);
      return {
        width: this.canvasWidth + 'px',
        height: faceImgLength + 'px',
        marginTop: '-' + faceImgLength + 'px'
      }
    },
    contentBoxStyle() {
      //font-family
      let fontFamily = '';
      for (let i = 0; i < this.fonts.length; i++) {
        if (this.$props.font === i) {  //this.$props.font => font-family
          fontFamily = this.fonts[i].text;
          break;
        }
      }

      //font size value
      let valFontSize = this.$props.font_size;

      let tdFontSizeLimit = [9, 14];// 터디 폰트사이즈 limit range 9~14
      if (valFontSize <= tdFontSizeLimit[0]) {
        valFontSize = tdFontSizeLimit[0];
      } else if (valFontSize >= tdFontSizeLimit[1]) {
        valFontSize = tdFontSizeLimit[1];
      }

      // 2줄일경우 폰트 고정
      const msgCurrent = this.sliceMessage(this.currentText);
      if(msgCurrent !== undefined){
        if (msgCurrent.indexOf('&') > -1) { //2줄
          valFontSize = 10;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isMultiRows = true;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isMultiRows = false;
        }
      }

      const baseFontSize = 16.3;  //this.ratioWidth(154), 폰트사이즈 10 기준일 때 실사이즈 px값
      const ratioFontSize = baseFontSize * valFontSize / 10;
      //font size
      let fontSize = this.canvasWidth * ratioFontSize / this.ratioWidth;

      let betweenLines = 'normal';  // 입력 메세지가 한 줄인 경우
      if (this.isMultiRows) {
        betweenLines = ( (this.canvasHeight - (15 * 2)) / 2 ) + 'px'; //터디에서 2줄 높이간격 고정
      }

      //--text-scale-x
      //--text-scale-y
      let valTextX = this.$props.line_height;
      let valTextY = this.$props.letter_spacing;
      /*
      let defaultTextX = 10;   // 터디에서 범위 조정 : 6~23 > 5~20
      let defaultTextY = 100;  // 터디에서 범위 조정 : 80~120 > 2.64~4
      let textScaleX = this.mapValueInNewRange(valTextX, 6, 23, 5, 20);//(valTextX - 6) * (20 - 5) / (23 - 6) + 5;
      let textScaleY = this.mapValueInNewRange(valTextY, 80, 120, 2.64, 4);//(valTextY - 80) * (4 - 2.64) / (120 - 80) + 2.64;
      defaultTextX = this.mapValueInNewRange(defaultTextX, 6, 23, 5, 20);//((defaultTextX - 6) * (20 - 5)) / (23 - 6) + 5;
      defaultTextY = this.mapValueInNewRange(defaultTextY, 80, 120, 2.64, 4);//((defaultTextY - 80) * (4 - 2.64)) / (120 - 80) + 2.64;
      */
      let toScaleX = this.mapValueInNewRange(valTextX, 10,23,1.2,1.75);
      if(toScaleX < 1.2){
        toScaleX = 1.2
      }
      let toScaleY = this.mapValueInNewRange(valTextY, 100,120,1,1.043);
      if(toScaleY < 1){
        toScaleY = 1
      }

      if ( this.isMultiRows && this.animation !== 2 && this.animation !== 5 ) { //트레이싱, 날아오기의 경우 두 줄 불가능
        document.documentElement.style.setProperty('--text-scale-x', 1.2);
        document.documentElement.style.setProperty('--text-scale-y', 0.9);
        document.documentElement.style.setProperty('--transform-origin', '50% 50%');
        document.documentElement.style.setProperty('--text-left-space', '0px');
        this.setPlaceItems(true);

      }else{
        document.documentElement.style.setProperty('--text-scale-x', toScaleX);
        document.documentElement.style.setProperty('--text-scale-y', toScaleY);
        document.documentElement.style.setProperty('--transform-origin', 'left');
        document.documentElement.style.setProperty('--text-left-space', this.textLeftSpace + 'px');
        this.setPlaceItems(false);
      }

      if (this.animation === 6 || this.animation === 7) { // 애니메이션이 얼굴,반전인 경우
        this.setFaceTextSetUp();
      }

      return {
        '--font-family': fontFamily,
        '--font-size': fontSize + 'px',
        '--font-color': this.$props.font_color,
        '--line-height': betweenLines
      }
    },
  },
  methods: {
    setPlaceItems(isCenter) {
      if (isCenter) {
        document.documentElement.style.setProperty('--place-items', `center`);
        document.documentElement.style.setProperty('--canvas-text-align', `center`);
        document.documentElement.style.setProperty('--canvas-white-space', `normal`);
      } else {
        document.documentElement.style.setProperty('--place-items', `center start`);
        document.documentElement.style.setProperty('--canvas-text-align', `left`);
        document.documentElement.style.setProperty('--canvas-white-space', `nowrap`);
      }
    },
    handleResize() {
      let maxWidth = this.$parent.$refs.previewWrap.offsetWidth;
      let maxHeight = this.$parent.$refs.previewWrap.offsetHeight;

      //캔버스 크기 조절
      this.canvasWidth = maxWidth;
      this.canvasHeight = maxHeight;

      if (this.ratioWidth >= this.ratioHeight) {
        this.canvasWidth = maxWidth / 100 * 100;
        this.canvasHeight = maxWidth / 100 * (this.ratioHeight * 100 / 154);

        this.canvasMarginTop = (maxHeight - this.canvasHeight) / 2;

      } else {
        this.canvasWidth = maxHeight / 100 * (this.ratioWidth * 100 / 38);
        this.canvasHeight = maxHeight / 100 * 100;

        this.canvasMarginLeft = (maxWidth - this.canvasWidth) / 2;
      }

      // canvas width 값
      document.documentElement.style.setProperty('--canvas-width', `${this.canvasWidth}px`);

      //svg 크기 조절
      this.svgViewBox = '0 0 ' + this.canvasWidth + ' ' + this.canvasHeight;
      this.svgWidth = this.canvasWidth;
      this.svgHeight = this.canvasHeight;
      // SVG width 값 업데이트
      document.documentElement.style.setProperty('--svg-width', `${this.svgWidth}px`);

      //facd img 설정
      let faceImgLength = this.getFaceImgLength(this.canvasWidth, this.canvasHeight);
      document.documentElement.style.setProperty('--face-img-length', faceImgLength + 'px');

      //텍스트 왼쪽 여백 설정
      this.textLeftSpace = this.canvasWidth * (5/100);
      document.documentElement.style.setProperty('--text-left-space', this.textLeftSpace + 'px');
    },
    getFaceImgLength(canvasWidth, canvasHeight) {
      let faceImgLength = 200;
      if (canvasWidth >= canvasHeight) {
        faceImgLength = canvasHeight;
      } else {
        faceImgLength = canvasWidth;
      }
      faceImgLength = (faceImgLength > 200) ? 200 : faceImgLength;
      return faceImgLength;
    },
    preview() {
      this.showMediaBtns = true;
      this.isDisabledPreBtn = true;
      this.play();  //재생
    },
    startSequence() {
      this.setTimer(this.remainingTime);
    },
    setTimer(duration) {
      this.clearTimers();
      this.timers.push(setTimeout(() => {
        if (this.currentText < this.numberOfSections) {
          this.currentText += 1;
          this.remainingTime = this.durations[this.currentText - 1];
          this.setTimer(this.remainingTime);
        } else {
          this.repeatCount += 1;
          if (this.repeatCount < this.maxRepeats) {
            this.currentText = 1;
            this.remainingTime = this.durations[0];
            this.setTimer(this.remainingTime);
          }
        }
      }, duration));

      if (this.animation === 7) { // 애니메이션이 반전인 경우
        if (this.currentText === 2) {
          this.canvasClass = 'invert-background-color';
        }
      }
    },
    clearTimers() {
      this.timers.forEach(timer => clearTimeout(timer));
      this.timers = [];
    },
    pause() {
      //console.log("pause");
      if (!this.isPaused) {
        this.pauseStartTime = Date.now();
        this.clearTimers();
        this.isPaused = true;
      }
    },
    mapValueInNewRange(value, in_min, in_max, out_min, out_max) { // in_ : as-is range / out_ : to-be range
      return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min
    },
    setTraceSvgSetUp() {
      this.$nextTick(() => {  // DOM 업데이트 후 실행되는 콜백
        //svg margin left
        document.documentElement.style.setProperty('--svg-margin-left', '0px');

        //svg margin top
        const traceText = this.$refs.traceText;
        //const contentBox = this.$refs.contentBox;

        let marginTop = 0;
        if (traceText) {
          //if (contentBox) {
          const height = traceText.offsetHeight;

          // 가져온 height 값을 이용하여 CSS 변수 설정
          marginTop = (this.canvasHeight - height) / 2;
          document.documentElement.style.setProperty('--svg-margin-top', `-${marginTop}px`);
        }

        let gapText = 0;//5;
        let gap1 = marginTop - gapText;
        let gap2 = this.canvasHeight - marginTop + gapText;

        let x1 = this.canvasWidth / 100 * 20;
        let x2 = this.canvasWidth / 100 * 60;
        let x3 = this.canvasWidth / 100 * 5;
        let x4 = this.canvasWidth / 100 * 35;
        let x5 = this.canvasWidth / 100 * 85;

        //svg line set
        this.svgLineVertical1 = 'M' + x1 + ' 0 V ' + gap1;
        this.svgLineVertical2 = 'M' + x2 + ' 0 V ' + gap1;
        this.svgLineTop = 'M' + this.canvasWidth + ' ' + gap1 + ' H 0';//'M0 '+gap1+' H '+this.canvasWidth;
        this.svgLineBottom = 'M0 ' + gap2 + ' H ' + this.canvasWidth;//'M'+this.canvasWidth+' '+gap2+' H 0';
        this.svgLineReverse1 = 'M' + x3 + ' ' + this.canvasHeight + ' V ' + gap2;
        this.svgLineReverse2 = 'M' + x4 + ' ' + this.canvasHeight + ' V ' + gap2;
        this.svgLineReverse3 = 'M' + x5 + ' ' + this.canvasHeight + ' V ' + gap2;

      });
    },
    setFaceTextSetUp(){
      if(this.isMultiRows){
        this.faceTextMarginTop = 0;
      }else{
        this.faceTextMarginTop = 30;
      }
      document.documentElement.style.setProperty('--face-text-margin-top', `${this.faceTextMarginTop}px`)
    },
    play() {
      //console.log("play");
      if (this.animation === 5) { // 애니메이션이 트레이싱인 경우
        this.setTraceSvgSetUp();
      }else if (this.animation === 6 || this.animation === 7) { // 애니메이션이 얼굴,반전인 경우
        this.setFaceTextSetUp;
      }

      this.currentText = 1; //첫번째 섹션 보임
      this.startSequence(); //애니메이션 시작

      if (this.isPaused) {
        const elapsedTime = Date.now() - this.pauseStartTime;
        this.remainingTime -= elapsedTime;
        this.setTimer(this.remainingTime);
        this.isPaused = false;
      }
    },
    resume() {
      //console.log("start");
      if (this.animation === 5) { // 애니메이션이 트레이싱인 경우
        this.setTraceSvgSetUp();
      }else if (this.animation === 6 || this.animation === 7) { // 애니메이션이 얼굴,반전인 경우
        this.setFaceTextSetUp();
      }

      if (this.isPaused) {
        const elapsedTime = Date.now() - this.pauseStartTime;
        this.remainingTime -= elapsedTime;
        this.setTimer(this.remainingTime);
        this.isPaused = false;
      }
    },
    restart() {
      //console.log("restart");
      if (this.animation === 5) { // 애니메이션이 트레이싱인 경우
        this.setTraceSvgSetUp();
      }else if (this.animation === 6 || this.animation === 7) { // 애니메이션이 얼굴,반전인 경우
        this.setFaceTextSetUp();
      }

      // 애니메이션 클래스 제거 후 재추가를 위한 플래그 설정
      this.animationClass1 = '';
      this.animationClass2 = '';
      this.animationClass3 = '';
      this.canvasClass = '';
      this.animationSubClass1 = '';
      this.animationSubClass2 = '';

      // 애니메이션을 잠시 멈추기 위해 타이머 설정
      setTimeout(() => {
        // 원래 애니메이션 클래스를 다시 설정하여 애니메이션 재시작
        let effectName = this.animationEffects[this.animationEffectIndex];
        let directionName = '';

        //애니메이션 효과가 날아오기일 경우
        if (effectName.indexOf('flying') > -1) {
          //방향 설정
          for (let i = 0; i < this.animation_directions.length; i++) {
            if (this.animation_directions[i].value === this.animation_direction) {
              directionName = this.animation_directions[i].code;
              break;
            }
          }
          this.animationClass1 = effectName + '-' + directionName;
          this.animationClass2 = effectName + '-' + directionName;
          this.animationClass3 = effectName + '-' + directionName;

        } else if (effectName.indexOf('invert2') > -1) {  // 애니메이션이 반전인 경우
          this.canvasClass = 'invert-background-color';

        } else {
          this.animationClass1 = effectName + '1';
          this.animationClass2 = effectName + '2';
          this.animationClass3 = effectName + '3';
        }

        this.animationSubClass1 = 'face-img1';
        this.animationSubClass2 = 'face-img1-box';
        
        this.clearTimers();
        this.repeatCount = 0;
        this.currentText = 1;
        this.remainingTime = this.durations[0];
        this.isPaused = false;

        // 애니메이션 시작
        this.startSequence();
      }, 50); // 50ms 후에 애니메이션 클래스 재추가
    },
    replaceSpaceToHtmlCode(message) {
      return message.replace(/\s/g, "&nbsp;");
    },
    sliceMessage(sectionIndex) {
      let message = this.$props.message || '';

      if (message.indexOf('%') === -1) {
        if (sectionIndex === 1) {
          this.numberOfSections = 1;
          return message; // 메시지 전체 반환
        } else {
          return ''; // 빈 문자열 반환
        }
      }

      const msgs = message.split('%', 3);
      this.numberOfSections = msgs.length; // 섹션 개수 설정
      return msgs[sectionIndex - 1];
    },
    selectEffect() {
      let effectName = "";
      let directionName = '';

      for (let i = 0; i < this.animations.length; i++) {
        if (this.animations[i].value === this.animation) {
          effectName = this.animations[i].code;
          this.animationEffectIndex = i;
          break;
        }
      }

      //애니메이션 효과가 날아오기일 경우
      if (effectName.indexOf('flying') > -1) {
        //방향 설정
        for (let i = 0; i < this.animation_directions.length; i++) {
          if (this.animation_directions[i].value === this.animation_direction) {
            directionName = this.animation_directions[i].code;
            break;
          }
        }
        this.animationClass1 = effectName + '-' + directionName;
        this.animationClass2 = effectName + '-' + directionName;
        this.animationClass3 = effectName + '-' + directionName;

      } else {
        this.animationClass1 = effectName + '1';
        this.animationClass2 = effectName + '2';
        this.animationClass3 = effectName + '3';
      }

      if ( this.animation === 2 || this.animation === 5 ) { //트레이싱, 날아오기의 경우
        document.documentElement.style.setProperty('--transform-origin', 'left');
        document.documentElement.style.setProperty('--text-left-space', this.textLeftSpace + 'px');
      }

      if (this.animation === 6 || this.animation === 7) { // 애니메이션이 얼굴,반전인 경우
        this.setFaceTextSetUp();
      }

    },
    selectDuration() {
      if (Array.isArray(this.sections) && this.sections.length >= 3) {
        this.durations = this.sections.map(section => section * 1000); // sections 값을 초로 변환
      }
      this.remainingTime = this.durations[0];
    },
    selectRepeat() {
      this.maxRepeats = this.repeat;
    },
    setDurationsToCSS() {
      document.documentElement.style.setProperty('--section-1-sec', `${this.durations[0] / 1000}s`);
      document.documentElement.style.setProperty('--section-2-sec', `${this.durations[1] / 1000}s`);
      document.documentElement.style.setProperty('--section-3-sec', `${this.durations[2] / 1000}s`);
    }
  }
};
</script>

<style scoped>

h4 {
  margin-top: 0;
  margin-bottom: 0;
}

input[type="text"] {
  flex-grow: 1;
  height: 35px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

select {
  flex-grow: 1;
  min-height: 35px;
  max-width: 300px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

button {
  width: 90px;
  height: 35px;
  background-color: #f5f5f8;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  color: #9b9b9b;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.canvas {
  overflow: hidden;
  background-color: var(--background-color);
  place-items: var(--place-items);
  text-align: var(--canvas-text-align);
  white-space: var(--canvas-white-space);
  display: grid;
}

.canvas.invert-background-color {
  animation: bg-color-dissolve var(--section-2-sec) forwards;
}

.contentBox {
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
}

.btn-wrap {
  float: right;
  display: flex;
  margin: 5px 0px;
}

.play-btn, .stop-btn, .refresh-btn {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  border: 1px solid #7a66fb;
  background-color: #fff;
  background-size: cover;
}

.play-btn {
  background-image: url('@/assets/cms/play.png');
}

.play-btn:disabled {
  cursor: default;
}

.stop-btn {
  background-image: url('@/assets/cms/stop.png');
}

.stop-btn:disabled {
  cursor: default;
}

.refresh-btn {
  background-image: url('@/assets/cms/refresh.png');
}

.preview-btn {
  background-color: white;
  color: rgb(122, 102, 251);
  border: 1px solid rgb(122, 102, 251);
}

.preview-btn:disabled {
  background-color: rgb(230, 230, 231);
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  cursor: default;
}

.text {
  transform-origin: var(--transform-origin);
  transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y));
}

.fadeIn1 {
  animation: fadeIn var(--section-1-sec) forwards;
}

.fadeIn2 {
  animation: fadeIn var(--section-2-sec) forwards;
}

.fadeIn3 {
  animation: fadeIn var(--section-3-sec) forwards;
}

.fadeOut1 {
  animation: fadeOut var(--section-1-sec) forwards;
}

.fadeOut2 {
  animation: fadeOut var(--section-2-sec) forwards;
}

.fadeOut3 {
  animation: fadeOut var(--section-3-sec) forwards;
}

.dissolve1 {
  animation: dissolve var(--section-1-sec) forwards;
}

.dissolve2 {
  animation: dissolve var(--section-2-sec) forwards;
}

.dissolve3 {
  animation: dissolve var(--section-3-sec) forwards;
}

.wipe1 {
  animation: wipe var(--section-1-sec) forwards;
}

.wipe2 {
  animation: wipe var(--section-2-sec) forwards;
}

.wipe3 {
  animation: wipe var(--section-3-sec) forwards;
}

.trace1 {
  animation: wipe 3s forwards;
}

.trace2 {
  animation: wipe 3s forwards;
}

.trace3 {
  animation: wipe 3s forwards;
}

.invert1 {
  color: #ffffff;
}

.invert2 {
  color: #000000;
}

@keyframes bg-color-dissolve {
  0% {
    background-color: #000000;
  }
  30% {
    background-color: #ffffff;
  }
  70% {
    background-color: #ffffff;
  }
  100% {
    background-color: #000000;
  }
}

@keyframes bg-color-white {
  from {
    background-color: #000000;
  }
  to {
    background-color: #ffffff;
  }
}

@keyframes bg-color-black {
  from {
    background-color: #ffffff;
  }
  to {
    background-color: #000000;
  }
}

.flying-rightToLeft {
  animation: flying-rightToLeft var(--section-1-sec) forwards;
  margin-left: var(--text-left-space);
  /*margin-left: 30px;*/
}

.flying-leftToRight {
  animation: flying-leftToRight var(--section-1-sec) forwards;
  margin-left: var(--text-left-space);
  /*margin-left: 30px;*/
}

.flying-bottomToTop {
  animation: flying-bottomToTop var(--section-1-sec) forwards;
  margin-left: var(--text-left-space);
  /*margin-left: 30px;*/
}

.flying-topToBottom {
  animation: flying-topToBottom var(--section-1-sec) forwards;
  margin-left: var(--text-left-space);
  /*margin-left: 30px;*/
}


.trace {
  position: relative;
  z-index: 1;
}

.line-drawing {
  margin-top: var(--svg-margin-top);
  margin-left: var(--svg-margin-left);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.trace-text {
  position: relative;
  z-index: 2;
  clip-path: none; /* clip-path 제거 */
}

.line {
  stroke: #ffffff;
  stroke-width: 2;
  fill: none;
}

.line.horizontal1 {
  animation: draw-and-erase-horizontal 3s linear forwards;
}

.line.horizontal2 {
  animation: draw-and-erase-horizontal 3s linear forwards;
}

.line.vertical1 {
  visibility: hidden;
  animation: show-line 0s linear 0.5s forwards, draw-and-erase-vertical 2s linear 0.5s forwards;
}

.line.vertical2 {
  visibility: hidden;
  animation: show-line 0s linear 1.5s forwards, draw-and-erase-vertical 2s linear 1.5s forwards;
}

.line.vertical-reverse1 {
  visibility: hidden;
  animation: show-line 0s linear 0.5s forwards, draw-and-erase-vertical 2s linear 0.5s forwards;
}

.line.vertical-reverse2 {
  visibility: hidden;
  animation: show-line 0s linear 1s forwards, draw-and-erase-vertical 2s linear 1s forwards;
}

.line.vertical-reverse3 {
  visibility: hidden;
  animation: show-line 0s linear 1.5s forwards, draw-and-erase-vertical 2s linear 1.5s forwards;
}

@keyframes draw-and-erase-horizontal {
  0% {
    stroke-dasharray: calc(var(--canvas-width) / 2) var(--canvas-width);
    stroke-dashoffset: calc(var(--canvas-width) / 2);
  }
  50% {
    stroke-dasharray: calc(var(--canvas-width) / 2) var(--canvas-width);
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: calc(var(--canvas-width) / 2) var(--canvas-width);
    stroke-dashoffset: calc(var(--canvas-width) * -1);
  }
}

@keyframes draw-and-erase-vertical {
  0% {
    stroke-dasharray: 100 200;
    stroke-dashoffset: 100;
  }
  50% {
    stroke-dasharray: 100 850;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 100 200;
    stroke-dashoffset: -200;
  }
}

@keyframes show-line {
  0% {
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

@keyframes draw-top {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 850;
  }
}

@keyframes draw-bottom {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-vertical {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-vertical-reverse {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes erase-top {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 850;
  }
}

@keyframes erase-bottom {
  to {
    stroke-dashoffset: 850;
  }
}

@keyframes erase-vertical {
  to {
    stroke-dashoffset: 200;
  }
}

@keyframes erase-vertical-reverse {
  to {
    stroke-dashoffset: 180;
  }
}


.textWrapper {
  position: relative;
}

.textWrapper .text {
  margin-left: var(--text-left-space);
}

.face {
  position: relative;
}

.face-img1 {
  width: var(--face-img-length);
  height: var(--face-img-length);
  object-fit: cover;
  position: absolute;
  animation: faceAppear 4s forwards, faceMoveAndDisappear 6s linear 4s forwards, faceDisappear 1s 9s forwards;
  z-index: 1;
}

.face-img1-box {
  background-color: var(--background-color);
  object-fit: cover;
  position: absolute;
  animation: faceBoxMoveAndDisappear 6s linear 4s forwards;
}

.face-text1 {
  transform-origin: var(--transform-origin);
  margin-top: var(--face-text-margin-top);
  transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y));
}

.face-text2 {
  animation: dissolve var(--section-2-sec) forwards;
}

@keyframes faceAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes faceDisappear {
  100% {
    opacity: 0;
  }
}

@keyframes faceMoveAndDisappear {
  0% {
    left: 20px;
  }
  100% {
    left: calc(var(--svg-width) - var(--face-img-length));
  }
}

@keyframes faceBoxMoveAndDisappear {
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 100%);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes flying-rightToLeft {
  from {
    transform: translateX(700px) scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y));
    opacity: 1;
  }
  to {
    transform: translateX(0px) scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y));
    opacity: 1;
  }
}

@keyframes flying-leftToRight {
  from {
    transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y)) translateX(-100%);
    opacity: 1;
  }
  to {
    transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y)) translateX(0px);
    opacity: 1;
  }
}

@keyframes flying-bottomToTop {
  from {
    transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y)) translateY(150%);
    opacity: 1;
  }
  to {
    transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y)) translateY(-5px);
    opacity: 1;
  }
}

@keyframes flying-topToBottom {
  from {
    transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y)) translateY(-150%);
    opacity: 1;
  }
  to {
    transform: scaleX(var(--text-scale-x)) scaleY(var(--text-scale-y)) translateY(5px);
    opacity: 1;
  }
}

@keyframes dissolve {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes trace {
  from {
    width: 0; /* 텍스트가 왼쪽에서 오른쪽으로 나타나는 효과 */
  }
  to {
    width: auto; /* 텍스트가 완전히 나타남 */
  }
}

@keyframes wipe {
  from {
    clip-path: inset(0 100% 0 0); /* 텍스트를 왼쪽으로 클립하여 보이지 않게 시작 */
  }
  to {
    clip-path: inset(0 0 0 0); /* 텍스트가 왼쪽에서 오른쪽으로 나타나도록 클립을 변경 */
  }
}

</style>

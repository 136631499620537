<template>
  <div class="popup-overlay" @click.self="closePopup">
    <div class="popup-content">
      <h2>PLAYLIST</h2>
      <div class="content-body">
        <div class="cover-image-container" @mouseover="hover = true" @mouseout="hover = false" @click="onImageClick">
          <img :src="localPlaylist.image" alt="Cover Image" class="cover-image">
          <div v-show="hover" class="cover-image-hover">
            <p>이미지 바꾸기</p>
          </div>
          <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">
        </div>
        <div class="input-form">
          <h2>TITLE</h2>
          <input type="text" v-model="localPlaylist.title" placeholder="Enter title">
          <h2>DESCRIPTION</h2>
          <textarea v-model="localPlaylist.description" placeholder="Enter description"></textarea>
        </div>
      </div>
      <div class="button-group">
        <button @click="closePopup">취소하기</button>
        <button id="save-button" @click="savePlaylist">저장하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios
export default {
  name: 'PlaylistPopup',
  props: {
    playlist: {
      type: Object,
    }
  },
  data() {
    return {
      hover: false,
      localPlaylist: this.playlist
    };
  },
  created() {
    if (!this.playlist) {
      this.localPlaylist = {
        title: '',
        description: '',
        image: require('@/assets/cms/playlist.png')
      };
    } else {
      this.localPlaylist = this.playlist;
      if (!this.localPlaylist.image || this.localPlaylist.image === '') {
        this.localPlaylist.image = require('@/assets/cms/playlist.png');
      } else {
        this.localPlaylist.image = this.localPlaylist.image.replace('public/', '');
      }
      if (this.playlist._id) {
        this.localPlaylist.id = this.playlist._id;
      }
    }
  },
  watch: {
    playlist: {
      handler(newValue) {
        if (newValue) {
          this.localPlaylist = newValue;
        } else {
          this.localPlaylist = {
            title: '',
            description: '',
            image: require('@/assets/cms/playlist.png')
          };
        }
      },
      deep: true
    }
  },
  methods: {
    closePopup(reload = false) {
      this.$emit('close', reload);
    },
    onImageClick() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.localPlaylist.image = URL.createObjectURL(file); // Update the cover image URL
    },
    async savePlaylist() {
      try {
        const formData = new FormData();
        if (this.localPlaylist.id) {
          formData.append('id', this.localPlaylist.id);
        }
        formData.append('image', this.$refs.fileInput.files[0]);
        formData.append('title', this.localPlaylist.title);
        formData.append('description', this.localPlaylist.description);

        const response = await axios.post('/cms/playlist/save', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          if (this.localPlaylist.id) {
            this.closePopup(true);
          } else {
            this.$router.push(`/playlist/edit/${response.data._id}`);
          }
        } else {
          console.error('Failed to save playlist');
        }
      } catch (error) {
        console.error('An error occurred while saving the playlist:', error);
      }
    },
  },
}
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

button {
  width: 90px;
  height: 35px; /* Set button height */
  background-color: #f5f5f8; /* Set button background color */
  border: 1px solid #9b9b9b; /* Remove button border */
  border-radius: 3px;
  color: #9b9b9b; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

#save-button {
  background-color: #7a66fb; /* Set button background color */
  color: white; /* Set button text color */
}

.input-form input[type="text"] {
  width: 100%;
  margin-bottom: 20px; /* adjust as needed */
  height: 35px;
  padding: 0 10px;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
}

.input-form textarea {
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 0;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  font: inherit;
  font-weight: 400;
  font-size: 14px;
  resize: none;
}

.popup-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  width: 1100px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 38px 48px;
}

.content-body {
  display: flex;
}

.cover-image-container {
  position: relative;
  width: 684px; /* adjust as needed */
  height: 385px; /* adjust as needed */
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Add this to make the image cover its container */
}

.cover-image-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  z-index: 1; /* Add this to set the z-index higher */
}

.input-form {
  width: 50%; /* adjust as needed */
  padding: 0 20px; /* adjust as needed */
  display: flex;
  flex-direction: column;
}

.input-form h2 {
  /* add styles for h2 elements if needed */
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* adjust as needed */
}

.button-group button {
  margin-left: 10px; /* adjust as needed */
}
</style>
<template>
  <div class="container">
    <PlaylistContainer
        itemContainer="PlaylistItemContainer"
        :initialPage="currentPage"
        :itemsPerPage="12"
    />
  </div>
</template>

<script>
import PlaylistContainer from "./PlaylistContainer.vue";

export default {
  components: {
    PlaylistContainer,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1;
  },
}
</script>

<style scoped>
.container {
  display: grid;
  gap: 20px; /* Add a 20px gap between the columns */
}
</style>
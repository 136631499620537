<template>
  <div class="block">
    <div style="display: flex;">
      <h2 style="display: inline-block; margin-right: 30px">TIMELINE
        <img src="@/assets/cms/guide.svg" alt="가이드" v-on:mouseover="showGuide = true" v-on:mouseout="showGuide = false"/>
        <div v-if="showGuide" class="guide-image">
          <img src="@/assets/cms/guide_timeline.png" alt="Guide Timeline">
        </div>
      </h2>
      <div class="timeline-options-container">
        <TimelineDateSelectorContainer v-on:dateSelected="onDateSelected"/>
        <TimelineContentsPriorityContainer
            v-on:contentPriorityChanged="onContentPriorityChanged"
            :vipFirst="selectedTimeline.vipFirst"
            :editable="!timelineCapture && !specificTimeline"
        />
        <div>
          <TimelinePeriodContainer
              v-on:periodChanged="onPeriodChanged"
              :startDate="selectedTimeline.startDate"
              :endDate="selectedTimeline.endDate"
              :editable="!timelineCapture && !specificTimeline"
          />
          <TimelineDaysOfWeekContainer
              v-on:daysChanged="onDaysChanged"
              :daysOfWeek="selectedTimeline.daysOfWeek"
              :editable="!timelineCapture && !specificTimeline"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="timeline-tabs">
        <TimelineListContainer
            v-if="!timelineCapture && !specificTimeline"
            :timelines="timelines"
            :selectedTimelineIndex="selectedTimelineIndex"
            :activeTimeline="activeTimeline"
            @selectTimeline="selectTimeline"
            @createTimeline="createTimeline"
            @deleteTimeline="deleteTimelineIndex = $event; showDeleteTimelinePopup = true"
            @nameChange="nameChange"
        />
        <div class="right-aligned">
          <button type="button" class="icon-button" @click="zoomIn">
            <img src="@/assets/cms/zoom_in.svg" alt="확대"/>
          </button>
          <button type="button" class="icon-button" @click="zoomOut">
            <img src="@/assets/cms/zoom_out.svg" alt="축소"/>
          </button>
        </div>
      </div>
      <div class="timeline">
        <div class="header">
          <span>시간</span>
          <span class="line"></span>
          <span>VIP 콘텐츠</span>
          <span class="line"></span>
          <span>고정 콘텐츠</span>
        </div>
        <div class="time-body-container-container">
          <div class="time-body-container">
            <div class="current-time-line" v-show="!timelineCapture && !specificTimeline" :style="{ top: 'calc(' + currentTimePercentage + '% - 11px)' }">
              <div class="clock">
                <span>{{ new Date().toLocaleTimeString('ko-KR', {hour: '2-digit', minute: '2-digit', hour12: false}) }}</span>
              </div>
              <div class="time-pointer"/>
              <div class="current-content">
                <span style="color: #afa3fd; text-align: start;">{{ prevContent }}</span>
                <span style="text-align: start;">{{ currentContent }}</span>
                <span style="color: #afa3fd; text-align: start;">{{ nextContent }}</span>
              </div>
            </div>
            <div class="time-body">
              <div class="background">
                <div class="dashed-background" v-for="i in 24" :key="i"/>
                <div class="spacer"/>
              </div>
              <div class="time-list">
                <div class="spacer"/>
                <div class="time" v-for="time in 24" :key="time">
                  <span>{{ time < 10 ? '0' + time : time }}:00</span>
                </div>
              </div>
              <div class="vip-list">
                <div class="vip-item-list">
                  <draggable class="object-list"
                             :component-data="{
                                                          tag: 'ul',
                                                          type: 'transition-group',
                                                          name: !vipDrag ? 'flip-list' : null
                                                        }"
                             v-model="selectedTimeline.vip"
                             @change="onVIPTimelineChange"
                             item-key="index"
                             :group="vipGroup"
                             :disabled="timelineCapture || specificTimeline"
                  >
                    <template #item="{ element, index}">
                      <TimelineContentItemContainer
                          :content="element"
                          :index="index"
                          :start="element.start"
                          :end="element.end"
                          :hourHeight="hourHeight"
                          :editable="!timelineCapture && !specificTimeline"
                          @edit="editingVIPTimeBlockIndex = index; editVIPTimeBlock = true"
                      />
                    </template>
                  </draggable>
                </div>
              </div>
              <div class="idle-list">
                <div class="idle-item-list">
                  <draggable class="object-list"
                             :component-data="{
                                                          tag: 'ul',
                                                          type: 'transition-group',
                                                          name: !idleDrag ? 'flip-list' : null
                                                        }"
                             v-model="selectedTimeline.idle"
                             v-bind="idleDragOptions"
                             @start="idleDrag = true"
                             @end="idleDrag = false"
                             @change="onIdleTimelineChange"
                             item-key="index"
                             :group="idleGroup"
                             :disabled="timelineCapture || specificTimeline"
                  >
                    <template #item="{ element, index}">
                      <TimelinePlaylistItemContainer
                          :playlist="element"
                          :index="index"
                          :start="element.start"
                          :end="element.end"
                          :hourHeight="hourHeight"
                          :editable="!timelineCapture && !specificTimeline"
                          @edit="editingIdleTimeBlockIndex = index; editIdleTimeBlock = true"
                      />
                    </template>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TimeBlockEditPopup
          v-if="editVIPTimeBlock"
          :name="selectedTimeline.vip[editingVIPTimeBlockIndex].message"
          :start="selectedTimeline.vip[editingVIPTimeBlockIndex].start"
          :end="selectedTimeline.vip[editingVIPTimeBlockIndex].end"
          :timeRemaining="24 * 60 * 60"
          @close="editVIPTimeBlock = false"
          @save="saveVIPTimeBlock"
      />
      <TimeBlockEditPopup
          v-if="editIdleTimeBlock"
          :name="selectedTimeline.idle[editingIdleTimeBlockIndex].name"
          :start="selectedTimeline.idle[editingIdleTimeBlockIndex].start"
          :end="selectedTimeline.idle[editingIdleTimeBlockIndex].end"
          :timeRemaining="24 * 60 * 60"
          @close="editIdleTimeBlock = false"
          @save="saveIdleTimeBlock"
      />
    </div>
    <ConfirmPopup message="TIMELINE을 삭제하시겠습니까?" @confirm="deleteTimeline(deleteTimelineIndex)"
                  @close="showDeleteTimelinePopup = false" v-if="showDeleteTimelinePopup"/>
  </div>
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable'
import TimelineContentItemContainer from "@/components/cms/TimelineContentItemContainer.vue";
import TimelinePlaylistItemContainer from "@/components/cms/TimelinePlaylistItemContainer.vue";
import TimeBlockEditPopup from "@/components/cms/TimeBlockEditPopup.vue";
import TimelineDateSelectorContainer from "@/components/cms/TimelineDateSelectorContainer.vue";
import TimelineContentsPriorityContainer from "@/components/cms/TimelineContentsPriorityContainer.vue";
import TimelinePeriodContainer from "@/components/cms/TimelinePeriodContainer.vue";
import TimelineDaysOfWeekContainer from "@/components/cms/TimelineDaysOfWeekContainer.vue";
import TimelineListContainer from "@/components/cms/TimelineListContainer.vue";
import ConfirmPopup from "@/components/cms/ConfirmPopup.vue";

export default {
  components: {
    ConfirmPopup,
    TimelineListContainer,
    TimelineDaysOfWeekContainer,
    TimelineContentsPriorityContainer,
    TimelineDateSelectorContainer,
    TimelinePeriodContainer,
    draggable,
    TimelineContentItemContainer,
    TimelinePlaylistItemContainer,
    TimeBlockEditPopup
  },
  props: {
    activeTimeline: Object,
  },
  data() {
    return {
      currentTimePercentage: 0,
      timelines: [],
      selectedTimelineIndex: 0,
      editingVIPTimeBlockIndex: null,
      editVIPTimeBlock: false,
      editingIdleTimeBlockIndex: null,
      editIdleTimeBlock: false,
      idleDrag: false,
      vipDrag: false,
      idleGroup: 'timeline-idle',
      vipGroup: 'timeline-vip',
      hourHeight: 45,
      deleteTimelineIndex: null,
      showDeleteTimelinePopup: false,
      prevContent: '',
      currentContent: '',
      nextContent: '',
      showGuide: false,
      timelineCapture: null,
      specificTimeline: null,
    }
  },
  created() {
    this.loadTimelines();
    setInterval(this.updateTime, 1000) // Update every 1 second
  },
  mounted() {
    this.updateHourHeight();
    this.scrollToCurrentTime();
  },
  watch: {
    hourHeight: function () {
      this.updateHourHeight();
    },
    activeTimeline: function () {
      this.reorderTimeline();
    },
  },
  computed: {
    selectedTimeline() {
      if(this.timelineCapture){
        return this.timelineCapture;
      }
      if(this.specificTimeline){
        return this.specificTimeline;
      }
      if (this.timelines.length === 0) return {
        idle: []
      }
      return this.timelines[this.selectedTimelineIndex];
    },
    idleDragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    idleTimeRemaining() {
      if (this.selectedTimeline.idle.length === 0) return 24 * 60 * 60;
      return 24 * 60 * 60 - this.selectedTimeline.idle.reduce((acc, time) => {
        if ('start' in time && 'end' in time) {
          return acc + (time.end - time.start)
        }
        return acc
      }, 0)
    }
  },
  methods: {
    async createTimeline() {
      try {
        const response = await axios.post('/cms/timeline/create');
        this.timelines.push(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteTimeline(index) {
      try {
        const timelineId = this.timelines[index]._id;
        await axios.delete(`/cms/timeline/delete/${timelineId}`);
        this.timelines.splice(index, 1);
        this.showDeleteTimelinePopup = false;
      } catch (error) {
        console.error(error);
      }
    },
    async nameChange(id, name) {
      try {
        const timelineId = id;
        await axios.put(`/cms/timeline/update/${timelineId}`, {name});
      } catch (error) {
        console.error(error);
      }
    },
    formatDateToYYYYMMDD(date){
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}${month}${day}`;
    },
    async onDateSelected(date) {
      if (!date) {
        this.timelineCapture = null;
        this.specificTimeline = null;
        return;
      }

      const now = new Date();
      const nowFormatted = this.formatDateToYYYYMMDD(now);
      const dateFormatted = this.formatDateToYYYYMMDD(date);

      const isoDate = new Date(date).toISOString();
      if(nowFormatted > dateFormatted){ //past timeline search (timelinecapture)
        await axios.get(`/cms/timelinecapture/${isoDate}`).then(response => {
          this.timelineCapture = response.data;
          if (!this.timelineCapture) {
            alert('해당 날짜의 타임라인이 존재하지 않습니다.');
            return;
          }
          for (let i = 0; i < this.timelineCapture.idle.length; i++) {
            this.timelineCapture.idle[i].start = this.timelineCapture.idleTime[i].start;
            this.timelineCapture.idle[i].end = this.timelineCapture.idleTime[i].end;
          }
          for (let i = 0; i < this.timelineCapture.vip.length; i++) {
            this.timelineCapture.vip[i].start = this.timelineCapture.vipTime[i].start;
            this.timelineCapture.vip[i].end = this.timelineCapture.vipTime[i].end;
          }
        });

      }else{ //current and future timeline search
        await axios.get(`/cms/specificTimeline/${isoDate}`).then(response => {
          this.specificTimeline = response.data;

          if (!this.specificTimeline) {
            alert('해당 날짜의 타임라인이 존재하지 않습니다.');
            return;
          }
          for (let i = 0; i < this.specificTimeline.idle.length; i++) {
            this.specificTimeline.idle[i].start = this.specificTimeline.idleTime[i].start;
            this.specificTimeline.idle[i].end = this.specificTimeline.idleTime[i].end;
          }
          for (let i = 0; i < this.specificTimeline.vip.length; i++) {
            this.specificTimeline.vip[i].start = this.specificTimeline.vipTime[i].start;
            this.specificTimeline.vip[i].end = this.specificTimeline.vipTime[i].end;
          }
        });
      }
    },
    updateTime() {
      const now = new Date();
      const totalMinutes = now.getHours() * 60 + now.getMinutes();
      // this.currentTimePercentage = totalMinutes / (24.5 * 60) * 100;
      this.currentTimePercentage = totalMinutes * 100 / 1470;

      // request current content
      axios.get('/api/currentContent').then(response => {
        this.prevContent = response.data['prev'];
        this.currentContent = response.data['current'];
        this.nextContent = response.data['next'];
      });
    },
    onContentPriorityChanged(value) {
      this.selectedTimeline.vipFirst = value === 'VIP콘텐츠';
      this.saveTimeline();
    },
    onPeriodChanged(value) {
      this.selectedTimeline.startDate = value.startDate;
      this.selectedTimeline.endDate = value.endDate;
      this.saveTimeline();
    },
    onDaysChanged(value) {
      this.selectedTimeline.daysOfWeek = value;
      this.saveTimeline();
    },
    updateHourHeight() {
      document.documentElement.style.setProperty('--hour-height', `${this.hourHeight}px`);
    },
    scrollToCurrentTime() {
      const now = new Date();
      const totalMinutes = now.getHours() * 60 + now.getMinutes();
      const percentageOfDay = totalMinutes / (24 * 60);
      const scrollContainer = this.$el.querySelector('.time-body-container-container');
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTo = scrollHeight * percentageOfDay - scrollContainer.clientHeight / 2;
      scrollContainer.scrollTo(0, scrollTo);
    },
    selectTimeline(index) {
      this.selectedTimelineIndex = index;
    },
    onIdleTimelineChange(evt) {
      if (evt && 'added' in evt) {
        if (this.idleTimeRemaining <= 0) {
          this.selectedTimeline.idle.pop();
          return;
        }
      }
      //remove empty playlist
      this.selectedTimeline.idle = this.selectedTimeline.idle.filter(item => item.contents.length > 0);
      //remove duplicate playlist
      this.selectedTimeline.idle = this.selectedTimeline.idle.filter((item, index, self) =>
          index === self.findIndex((t) => (
              t._id === item._id
          ))
      );
      // if any item is in idle but has no attribute 'start' and 'end'
      // set it to the first available time slot
      // duration 1 hour if available, else maximum
      this.selectedTimeline.idle.forEach((item, index) => {
        if ('start' in item && 'end' in item) return;
        let start = 0;
        let newStart = 0;
        let end = 0;
        let newEnd = 3600;
        while (start !== newStart || end !== newEnd) {
          start = newStart;
          end = newEnd;
          // find the item that contains start in range
          for (let i = 0; i < this.selectedTimeline.idle.length; i++) {
            if (this.selectedTimeline.idle[i].start <= newStart && this.selectedTimeline.idle[i].end > newStart) {
              newStart = this.selectedTimeline.idle[i].end;
              break;
            }
          }
          newEnd = Math.min(start + 3600, 24 * 60 * 60);
          // find the item that contains end in range
          for (let i = 0; i < this.selectedTimeline.idle.length; i++) {
            if (this.selectedTimeline.idle[i].start < newEnd && this.selectedTimeline.idle[i].end >= newEnd) {
              newEnd = this.selectedTimeline.idle[i].start;
              break;
            }
          }

        }
        this.selectedTimeline.idle[index] = {
          ...item,
          start,
          end
        }
      });

      this.saveTimeline()
    },
    onVIPTimelineChange() {
      // if any item is in vip but has no attribute 'start' and 'end'
      // set it to the first available time slot
      // duration 1 hour if available, else maximum
      this.selectedTimeline.vip.forEach((item, index) => {
        if ('start' in item && 'end' in item) return;
        let start = 0;
        // find the item that contains start in range
        for (let i = 0; i < this.selectedTimeline.vip.length; i++) {
          if (this.selectedTimeline.vip[i].start <= start && this.selectedTimeline.vip[i].end > start) {
            start = this.selectedTimeline.vip[i].end;
            break;
          }
        }
        let end = Math.min(start + 3600, 24 * 60 * 60);
        // find the item that contains end in range
        for (let i = 0; i < this.selectedTimeline.vip.length; i++) {
          if (this.selectedTimeline.vip[i].start < end && this.selectedTimeline.vip[i].end >= end) {
            end = this.selectedTimeline.vip[i].start;
            break;
          }
        }
        this.selectedTimeline.vip[index] = {
          ...item,
          start,
          end
        }
      });
      this.saveTimeline()
    },
    async loadTimelines() {
      try {
        const response = await axios.get('/cms/timeline/list');
        this.timelines = response.data;
        this.reorderTimeline();

        // If no timelines are loaded, create a new one
        if (this.timelines.length === 0) {
          await this.createTimeline();
        }
      } catch (error) {
        console.error(error);
      }
    },
    reorderTimeline() {
      // reorder timelines so that the active timeline is the first
      // from the second timeline, if now is in range of startDate and endDate and daysOfWeek includes today, go to top
      // others go to bottom
      const now = new Date();
      const today = now.getDay();

      const daysOfWeekMap = [
        6, 0, 1, 2, 3, 4, 5,
      ];
      for (let i = 0; i < this.timelines.length; i++) {
        const timeline = this.timelines[i];
        const startDate = new Date(timeline.startDate);
        const endDate = new Date(timeline.endDate);
        if(!timeline.startDate){
          console.log(timeline.name + ' has no start date');
        }
        if(!timeline.endDate){
          console.log(timeline.name + ' has no end date');
        }
        if ((!timeline.startDate || startDate <= now) && (!timeline.endDate || endDate >= now)){
          console.log(timeline.name + ' is in range');
          if (timeline.daysOfWeek.length === 0 || timeline.daysOfWeek.includes(daysOfWeekMap[today])) {
            console.log(timeline.name + ' is in range and today');
            timeline.conditionTrue = true;
          } else {
            timeline.conditionTrue = false;
          }
        } else {
          timeline.conditionTrue = false;
        }
      }
      const beforeSort = this.timelines.map((timeline) => timeline._id);
      this.timelines.sort((a, b) => {
        if (a.conditionTrue && !b.conditionTrue) return -1;
        if (!a.conditionTrue && b.conditionTrue) return 1;
        return 0;
      });
      if (beforeSort.join() !== this.timelines.map((timeline) => timeline._id).join()) {
        this.selectedTimelineIndex = 0;
      }
      if (this.activeTimeline) {
        const activeTimelineIndex = this.timelines.findIndex(timeline => timeline._id === this.activeTimeline._id);
        if (activeTimelineIndex !== -1) {
          this.timelines.forEach(timeline => timeline.active = false);
          this.timelines[activeTimelineIndex].active = true;
          this.timelines.unshift(this.timelines.splice(activeTimelineIndex, 1)[0]);
        }
      }
    },
    async saveTimeline() {
      try {
        const tempTimeline = structuredClone(this.selectedTimeline);
        delete tempTimeline.conditionTrue;
        delete tempTimeline.active;
        await axios.post('/cms/timeline/save', tempTimeline);
      } catch (error) {
        console.error(error);
      }
    },
    saveIdleTimeBlock({start, end}) {
      let duration = Math.abs(end - start);
      // check if any item contains start in range
      for (let i = 0; i < this.selectedTimeline.idle.length; i++) {
        if (i === this.editingIdleTimeBlockIndex) continue;
        if (this.selectedTimeline.idle[i].start <= start && this.selectedTimeline.idle[i].end > start) {
          start = this.selectedTimeline.idle[i].end;
          break;
        }
      }
      if (start >= end) {
        end = start + duration;
      }
      // check if any item contains end in range
      for (let i = 0; i < this.selectedTimeline.idle.length; i++) {
        if (i === this.editingIdleTimeBlockIndex) continue;
        if (this.selectedTimeline.idle[i].start < end && this.selectedTimeline.idle[i].end >= end) {
          end = this.selectedTimeline.idle[i].start;
          break;
        }
      }
      this.selectedTimeline.idle[this.editingIdleTimeBlockIndex].start = start;
      this.selectedTimeline.idle[this.editingIdleTimeBlockIndex].end = end;
      this.editIdleTimeBlock = false;
      this.saveTimeline()
    },
    saveVIPTimeBlock({start, end}) {
      let duration = end - start;
      // check if any item contains start in range
      for (let i = 0; i < this.selectedTimeline.vip.length; i++) {
        if (i === this.editingVIPTimeBlockIndex) continue;
        if (this.selectedTimeline.vip[i].start <= start && this.selectedTimeline.vip[i].end > start + 3600) {
          start = this.selectedTimeline.vip[i].end;
          break;
        }
      }
      if (start >= end) {
        end = start + duration;
      }
      // check if any item contains end in range
      for (let i = 0; i < this.selectedTimeline.vip.length; i++) {
        if (i === this.editingVIPTimeBlockIndex) continue;
        if (this.selectedTimeline.vip[i].start < end && this.selectedTimeline.vip[i].end >= end) {
          end = this.selectedTimeline.vip[i].start;
          break;
        }
      }
      this.selectedTimeline.vip[this.editingVIPTimeBlockIndex].start = start;
      this.selectedTimeline.vip[this.editingVIPTimeBlockIndex].end = end;
      this.editVIPTimeBlock = false;
      this.saveTimeline()
    },
    zoomIn() {
      if (this.hourHeight < 205) {
        this.hourHeight += 20;
        document.documentElement.style.setProperty('--hour-height', `${this.hourHeight}px`);
      }
    },
    zoomOut() {
      if (this.hourHeight > 45) {
        this.hourHeight -= 20;
        document.documentElement.style.setProperty('--hour-height', `${this.hourHeight}px`);
      }
    },
  }
}
</script>

<style scoped>
:root {
  --hour-height: 45px; /* Default value */
}

.block {
  background-color: #fff; /* White background color */
  border-radius: 10px; /* Set the corner radius to 10px for rounded corners */
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1); /* Add a slight drop shadow */
  padding: 10px 20px; /* Add some padding inside the block */
}

.left {
  min-height: 1000px;
}

.timeline-options-container {
  display: flex;
  margin-top: 20px;
  margin-left: auto;
  justify-content: flex-end;
  gap: 20px;
}

.timeline-tabs {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  padding-left: 20px;
}

.timeline {
  background-color: #fff; /* White background color */
  border-radius: 5px; /* Set the corner radius to 10px for rounded corners */
  overflow-y: hidden;
  min-height: 800px;
  max-height: 800px;
  padding: 5px 0 10px;
  border: 2px solid #ccccce;
  margin-top: -2px;
}

.header {
  display: grid;
  grid-template-columns: 3fr 2px 5fr 2px 14fr;
  padding: 10px 0;
  border-bottom: 2px solid #ccccce;
}

.header span {
  font-weight: bold;
  text-align: center;
}

.line {
  bottom: 0; /* Place underline at the bottom of the link */
  left: 0; /* Align underline with the left edge of the link */
  height: 100%; /* Stretch underline to full width of the link */
  width: 2px; /* Set underline thickness */
  background-color: #9b9b9b; /* Underline color */
}

.time-body-container-container {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 750px;
}

.time-body-container {
  position: relative;
}

.current-time-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 23px;
  z-index: 5;
  transition: top 0.5s linear;
}

.time-body {
  z-index: 2;
  display: grid;
  grid-template-columns: 3fr 5fr 14fr;
}

.time-list {
  display: grid;
  grid-template-rows: calc(var(--hour-height) / 2) repeat(24, var(--hour-height));
  background-color: #e6e6e7;
  margin: 0 30px;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  height: calc(var(--hour-height) - 1px);
}

.clock {
  width: 105px;
  height: 23px;
  background-color: #7a66fc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.clock span {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.time-pointer {
  position: absolute;
  top: 10px;
  left: 105px;
  width: calc(100% - 105px);
  height: 3px;
  background-color: #7a66fc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.current-content {
  position: absolute;
  right: 15px;
  top: 0;
  width: 500px;
  height: 23px;
  background-color: #7a66fc;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  text-overflow: ellipsis;
  user-select: none;
  overflow: hidden;
}

.current-content:hover {
  top: -28px;
  height: 79px;
}

.time-body-container-container::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
  height: 8px; /* Adjust height as desired for horizontal scrollbars */
}

.time-body-container-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.time-body-container-container::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.time-body-container-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Thumb hover color */
}

.vip-list {
  z-index: 3;
  position: relative;
}

.idle-list {
  z-index: 3;
  position: relative;
}

.background {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 15%;
  width: 100%;
  height: 100%;
}

.spacer {
  height: 23px;
}

.dashed-background {
  z-index: 1;
  height: calc(var(--hour-height) - 1px);
  border-bottom: 1px dashed #9b9b9b;
  margin: 0;
}

.vip-item-list {
  height: 100%;
}

.idle-item-list {
  height: 100%;
}

.object-list {
  height: 100%;
}

.right-aligned {
  margin-left: auto;
  display: inline-block;
}

.icon-button {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.guide-image {
  position: absolute;
  z-index: 10;
}
</style>
<template>
  <div>
    <h5>콘텐츠 우선도 설정</h5>
    <div class="radio-button-container">
      <div>
        <input type="radio" id="vip" value="VIP콘텐츠" :disabled="!editable" v-model="selectedContent" @click="contentPriorityChanged('VIP콘텐츠')">
        <label for="vip" class="button-style">VIP콘텐츠</label>
      </div>
      <div>
        <input type="radio" id="ep" value="ep 콘텐츠" :disabled="!editable" v-model="selectedContent" @click="contentPriorityChanged('ep 콘텐츠')">
        <label for="ep" class="button-style">ep 콘텐츠</label>
      </div>
    </div>
    <span>*VIP콘텐츠가 재생될 때 QR태그시<br>엣피 콘텐츠 노출여부 설정</span>
  </div>
</template>

<script>
export default {
  props: {
    vipFirst: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedContent: this.vipFirst ? 'VIP콘텐츠' : 'ep 콘텐츠'
    }
  },
  watch: {
    vipFirst(newVal) {
      this.selectedContent = newVal ? 'VIP콘텐츠' : 'ep 콘텐츠';
    }
  },
  methods: {
    contentPriorityChanged(content) {
      this.$emit('contentPriorityChanged', content);
    },
  }
}
</script>

<style scoped>

h5 {
  margin: 0 0 5px 0;
}

button {
  white-space: nowrap;
  background-color: #7a66fb; /* Set button background color */
  padding: 0 10px; /* Add padding to the button */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 14px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
}

span {
  display: block;
  margin-top: 10px;
  font-size: 9px;
  color: #9b9b9b;
}

.radio-button-container {
  display: inline-grid;
  margin-right: 10px;
  grid-template-columns: 100px 100px;
}

.radio-button-container input[type="radio"] {
  display: none;
}

.button-style {
  display: inline-block;
  height: 38px;
  padding: 0 15px;
  background-color: #f5f5f8;
  color: #9b9b9b;
  text-align: center;
  line-height: 38px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.radio-button-container input[type="radio"]:checked + .button-style {
  background-color: #7a66fb;
  color: white;
}
</style>

<template>
  <div class="parent-container">
    <button class="tab"
            @click="showList = !showList"
    >
      {{ timelines[localSelectedTimelineIndex]? timelines[localSelectedTimelineIndex].name:'' }}
    </button>
    <div v-if="showList" class="timeline-list">
      <button class="new-timeline" @click="$emit('createTimeline')">+타임라인 만들기</button>
      <div class="timeline-item" v-for="(timeline, index) in timelines" :key="timeline._id"
           @dblclick="selectTimeline(index); closeList()">
        <div class="column pointer purple">
          {{ (timeline.active)? '▶':''}}
        </div>
        <div class="column name" :class="{purple: timeline.conditionTrue}">
          <input v-if="isEditable[index]"
                 :id="`editable-${index}`"
                 v-model="timeline.name"
                 @blur="this.isEditable[index] = false;
                 this.$emit('nameChange', timeline._id, timeline.name)"
                 @keyup.enter.exact="$event.target.blur()"
                 @keyup.esc.exact="$event.target.blur()"
                 @keyup.tab.exact="$event.target.blur()"
                 @keyup.shift.tab.exact="$event.target.blur()"
                 maxlength="20"
          >
          <div v-else @click="makeEditable(index)" style="width: fit-content; text-overflow: ellipsis;"
               :title="
               `${timeline.name} | ${timeline.vipFirst ? 'VIP콘텐츠' : 'ep 콘텐츠'} | ${(timeline.startDate || timeline.endDate)? formatDate(timeline.startDate) + ' - ' + formatDate(timeline.endDate):''} | ${timeline.daysOfWeek.length? timeline.daysOfWeek.map(day => days[day]).join(', '):''}
               `">
            {{ timeline.name }}
          </div>
        </div>
        <div class="column date">
          {{ formatDate(timeline.startDate) }} - {{ formatDate(timeline.endDate) }}
        </div>
        <div class="column delete">
          <button @click="this.$emit('deleteTimeline', index)">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    timelines: Array,
    selectedTimelineIndex: Number,
    activeTimeline: Object,
  },
  data() {
    return {
      days: ['월', '화', '수', '목', '금', '토', '일'],
      localSelectedTimelineIndex: this.selectedTimelineIndex,
      showList: false,
      isEditable: this.timelines.map(() => false),
    }
  },
  watch: {
    selectedTimelineIndex(newIndex) {
      this.localSelectedTimelineIndex = newIndex;
    },
    timelines(newTimelines) {
      this.isEditable = newTimelines.map(() => false);
    },
  },
  methods: {
    selectTimeline(index) {
      this.localSelectedTimelineIndex = index;
      this.$emit('selectTimeline', index);
    },
    closeList() {
      this.showList = false;
    },
    formatDate(date) {
      if (!date) {
        return '0.0 00:00';
      }
      return moment(date).format('M.D HH:mm'); // adjust the format as needed
    },
    makeEditable(index) {
      this.isEditable[index] = true;
      this.$nextTick(() => {
        this.$el.querySelector(`#editable-${index}`).focus();
      });
    },
  },
}
</script>
<style scoped>
.parent-container {
  position: relative; /* This makes the child elements positioned relative to this container */
}

.tab {
  height: 35px;
  background-color: #fff;
  border-left: 2px solid #ccccce;
  border-right: 2px solid #ccccce;
  border-top: 2px solid #ccccce;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 10px;
  z-index: 7;
  position: relative;
}

.timeline-list {
  position: absolute;
  top: 100%;
  z-index: 6;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 0;
  border: 2px solid #ccccce;
  margin-top: -2px;
}

.timeline-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.timeline-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.timeline-list::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 10px;
}

.timeline-list::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.new-timeline {
  display: block;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  margin: 0 10px 0 auto;
  padding: 10px 0;
}

.timeline-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  column-gap: 10px;
}

.timeline-item:hover {
  background-color: #f5f5f8;
}

.column {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: black;
  white-space: nowrap;
}

.column.pointer {
  width: 15px;
}

.column.name {
  width: 200px;
}

.column.date {
  width: 140px;
  margin: 0 15px;
  text-align: center;
  user-select: none;
}

.column.delete button {
  width: 40px;
  background: none;
  border: none;
  cursor: pointer;
}

.purple {
  color: #7a66fc;
}
</style>
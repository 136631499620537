<template>
  <td class="list-item-container"
      :style="{ position: 'absolute', left: '0', top: `${timePercentage}%`, width: '100%' }">
    <div class="list-item" :style="{ height: `${height}px` }">
      <div class="header">
        <a class="title" @click="edit">{{ playlist.title }}</a>
        <p>{{ formatTime(start) }} - {{ formatTime(end) }}</p>
        <p>총 {{ playlist.contents.length }}개</p>
      </div>
      <div class="content-list">
        <div class="message-container" v-for="content in this.contents" :key="content? content._id:''">
          <p>{{ content? content.message:'' }}</p>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    playlist: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      required: true
    },
    hourHeight: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      contents: [],
    };
  },
  mounted() {
    this.loadContents();
  },
  watch: {
    playlist() {
      this.loadContents();
    }
  },
  computed: {
    timePercentage() {
      return this.start * 10 / 8820;
    },
    height() {
      return (this.end - this.start) * this.hourHeight / 3600 - 4;
    }
  },
  methods: {
    formatTime(value) {
      let hours = Math.floor(value / 3600);
      let minutes = Math.floor((value % 3600) / 60);

      // Pad the values to two digits
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');

      // Return the formatted time
      return `${hours}:${minutes}`;
    },
    edit() {
      if (!this.editable) return;
      this.$emit('edit', this.index);
    },
    loadContents() {
      axios.get(`/cms/playlist/load/${this.playlist._id}`)
          .then(response => {
            this.contents = response.data.contents
          })
          .catch(error => {
            console.error(error);
          });
    },
  }
}
</script>

<style scoped>

.list-item {
  flex-grow: 1;
  padding: 0 40px;
  margin: 0 15px;
  background-color: #e6e6e7;
  border-radius: 5px;
  color: black; /* Set the default text color to black */
  overflow: hidden;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-content: center;
}

.list-item-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px;
}

.list-item a {
  margin: auto 0;
  cursor: pointer;
}

.list-item p {
  font-size: 14px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.message-container {
  display: flex;
  align-items: center;
  height: 35px; /* Set the height as per your requirement */
}

.content-list {
  width: 100%;
  height: calc(100% - 58px);
  display: grid;
  grid-template-rows: repeat(auto-fill, 35px); /* Set the row height */
  gap: 2px;
  overflow-y: scroll;
  border-top: 2px solid #9b9b9b;
  padding: 5px 2px;
}

.content-list::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
  height: 8px; /* Adjust height as desired for horizontal scrollbars */
}

.content-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.content-list::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.content-list::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Thumb hover color */
}
</style>
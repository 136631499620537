<template>
  <div class="block">
    <div style="width: 100%; display: flex;">
      <h2>{{ title }}에 이미 추가된 CONTENT</h2>
      <div class="right-aligned">
        <button type="button" class="icon-button" @click="shuffle">
          <img style="margin: auto;" src="@/assets/cms/shuffle.svg" alt="셔플"/>
        </button>
        <button type="button" @click="cancel">취소하기</button>
        <button type="button" @click="showPopup = true">저장하기</button>
      </div>
    </div>
    <span style="border-bottom: 2px solid #9b9b9b; margin-top: 10px; margin-bottom: 10px; display: block;"></span>
    <PlaylistEditToolContainer
        idPrefix="content2"
        :allIsSelected="isAllSelected"
        :is-selected="isAllSelected"
        :selected-count="selectedCount"
        button-label="삭제하기"
        @select-all="selectAllItems"
        @button-clicked="removeButtonClicked"
    />
    <div class="scrollable">
      <draggable class="object-list"
                 :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
                 v-model="contents"
                 v-bind="dragOptions"
                 @start="drag = true"
                 @end="drag = false"
                 @add="onContentAdded"
                 item-key="id"
                 :group="group"
      >
        <template #item="{ element, index }">
          <PlaylistContentItemContainer
              :content="element"
              :index="index"
              :isSelected="element.selected"
              :inPlaylist="true"
              @edit="editContent(element._id)"
              @select="updateSelected"
          />
        </template>
      </draggable>
    </div>
  </div>
  <ConfirmPopup message="PLAYLIST을 저장하시겠습니까?" @confirm="save" @close="showPopup = false" v-if="showPopup"/>
</template>

<script>
import draggable from 'vuedraggable'
import PlaylistContentItemContainer from "./PlaylistContentItemContainer.vue";
import axios from 'axios';
import ConfirmPopup from "@/components/cms/ConfirmPopup.vue";
import PlaylistEditToolContainer from "@/components/cms/PlaylistEditToolContainer.vue";

export default {
  components: {
    PlaylistEditToolContainer,
    ConfirmPopup,
    draggable,
    PlaylistContentItemContainer,
  },
  props: {
    group: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      drag: false,
      playlistId: '',
      title: '',
      initialContents: [],
      contents: [],
      showPopup: false,
      isAllSelected: false,
      previousUrl : '',
    };
  },
  created() {
    // this.$on('add-selected', this.addContents);
    this.playlistId = this.$route.params.playlistId
    this.loadPlaylistContents()

    //이전 페이지 url 저장
    const previousRoute = JSON.parse(localStorage.getItem('previousRoute'));
    if (previousRoute) {
      this.previousUrl = previousRoute.path;
    }else{
      this.previousUrl = '/playlist';
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    selectedCount() {
      return this.contents.filter(content => content.selected).length;
    },
  },
  methods: {
    onContentAdded({newIndex}) {
      this.contents[newIndex].selected = false;
      let sample = this.contents[newIndex];
      this.contents.splice(newIndex, 1)
      let tempArray = []
      for (let i = 0; i < sample.repeat; i++) {
        tempArray.push(structuredClone(sample))
      }
      this.contents = [
        ...this.contents.slice(0, newIndex),
        ...tempArray,
        ...this.contents.slice(newIndex),
      ];
    },
    selectAllItems(isSelected) {
      this.contents = this.contents.map(content => ({ ...content, selected: isSelected }));
      this.isAllSelected = isSelected;
    },
    updateSelected({selected, index}) {
      this.contents[index].selected = selected;
      this.isAllSelected = this.contents.every(content => content.selected);
    },
    removeButtonClicked() {
      this.contents = this.contents.filter(content => !content.selected);
    },
    addContents(contents) {
      let tempArray = []
      for (let content of contents) {
        tempArray.push(structuredClone(content))
      }
      this.contents = [
        ...this.contents,
        ...tempArray,
      ];
    },
    loadPlaylistContents() {
      axios.get(`/cms/playlist/load/${this.playlistId}`)
          .then(response => {
            this.title = response.data.title;
            this.contents = response.data.contents;
            this.initialContents = structuredClone(this.contents);
            console.log(this.contents)
          })
          .catch(error => {
            console.error(error);
          });
    },
    editContent(contentId) {
      this.$router.push({
        name: 'ContentEditPage',
        params: {contentId: contentId},
        query: {playlistId: this.playlistId}
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    async save() {
      try {
        const contentIds = this.contents.map(content => content._id);
        const response = await axios.post('/cms/playlist/save', {
          id: this.playlistId,
          contents: contentIds
        });

        // Check the response status
        if (response.status === 200) {
          this.initialContents = structuredClone(this.contents);

          //this.$router.go(-1);
          if (this.previousUrl) {
            this.$router.push(this.previousUrl);
          } else {
            this.$router.push('/playlist')
          }
        } else {
          console.error('Failed to save playlist');
        }
      } catch (error) {
        console.error('An error occurred while saving the playlist:', error);
      }
    },
    hasContentsChanged() {
      return JSON.stringify(this.contents) !== JSON.stringify(this.initialContents);
    },
    shuffle() {
      //shuffle the contents
      const idArray = this.contents.map(item => item._id);
      const reorderedIdArray = this.customSort(idArray);
      this.contents = this.sortObjectsById(reorderedIdArray)
    },
    customSort(idArray) {
      const countOccurrences = idArray.reduce((acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      }, {});

      const sortedEntries = Object.entries(countOccurrences).sort((a, b) => b[1] - a[1]);
      const mostFrequentCount = sortedEntries[0][1];
      const mostFrequentId = sortedEntries[0][0];
      const otherElements = idArray.filter(id => id !== mostFrequentId);
      const mostFrequentElements = idArray.filter(id => id === mostFrequentId);

      if (Math.floor(mostFrequentCount / 3) > otherElements.length) {
        return this.specialRearrange(mostFrequentElements, otherElements, mostFrequentCount);
      } else {
        return this.ensureNoFourConsecutive([...mostFrequentElements, ...otherElements]);
      }
    },
    specialRearrange(mostFrequentElements, otherElements, mostFrequentCount) {
      const result = [];
      const interval = Math.floor(mostFrequentCount / (otherElements.length + 1));

      let otherIndex = 0;

      for (let i = 0; i < mostFrequentElements.length; i++) {
        result.push(mostFrequentElements[i]);
        if ((i + 1) % interval === 0 && otherIndex < otherElements.length) {
          result.push(otherElements[otherIndex++]);
        }
      }

      // Append any remaining other elements
      while (otherIndex < otherElements.length) {
        result.push(otherElements[otherIndex++]);
      }

      return result;
    },
    ensureNoFourConsecutive(idArray) {
      const isValid = (idArray) => {
        for (let i = 0; i < idArray.length - 3; i++) {
          if (idArray[i] === idArray[i + 1] &&
              idArray[i] === idArray[i + 2] &&
              idArray[i] === idArray[i + 3]) {
            return false;
          }
        }
        return true;
      };

      const tryRearrange = (idArray) => {
        for (let attempt = 0; attempt < 1000; attempt++) {
          const shuffledArray = idArray.slice().sort(() => Math.random() - 0.5);
          if (isValid(shuffledArray)) return shuffledArray;
        }

        return idArray;
      };

      return tryRearrange(idArray);
    },
    sortObjectsById(idArray) {
      const reorderedArray = this.contents.map((item) => item);
      const idToObjectMap = reorderedArray.reduce((acc, obj) => {
        acc[obj._id] = obj;
        return acc;
      }, {});

      return idArray.map(id => idToObjectMap[id]);
    }
  },
};
</script>

<style scoped>
h2 {
  margin-right: auto;
}

button {
  width: 75px;
  height: 27px; /* Set button height */
  background-color: #7a66fb; /* Set button background color */
  border: none; /* Remove button border */
  border-radius: 3px;
  color: white; /* Set button text color */
  cursor: pointer; /* Indicate clickable behavior */
  font-size: 12px; /* Set button text size */
  font-weight: 500; /* Set button text weight */
  margin-left: 10px; /* Add a margin between input and button */
  margin-top: auto;
  margin-bottom: auto;
}

.icon-button {
  display: flex;
  width: 27px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.block {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
}

.right-aligned {
  margin-left: auto; /* Alternatively, use margin-left: auto for right alignment */
  display: flex;
  align-items: center;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 1000px;
}

.object-list {
  display: flex;
  min-height: 500px;
  row-gap: 9px;
  flex-direction: column;
}

.ghost {
  opacity: 0.5;
}

.list-move {
  transition: transform 0.5s;
}

.scrollable::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
  height: 8px; /* Adjust height as desired for horizontal scrollbars */
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Thumb hover color */
}
</style>